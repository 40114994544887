<script>
import { layoutComputed, adminMethods, adminComputed, levelMethods, levelComputed, guidelineComputed, guidelineMethods, activityMethods } from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";

import Loading from "@/components/widgets/loadingBlock.vue";

import LoginOverlay from "../views/account/loginOverlay.vue";
import GlobalGuidelineModal from "../../components/widgets/globalGuidelineModal.vue";
export default {
  components: {
    Vertical,
    Horizontal,
    Loading,
    LoginOverlay,
    GlobalGuidelineModal,
  },
  data() {
    return {
      mount_tries: 0,
    };
  },
  computed: {
    ...adminComputed,
    ...layoutComputed,
    ...levelComputed,
    ...guidelineComputed,
    hasCoreLoaded() {
      return this.core_loaded;
    },
    show_guideline_modal() {
      return this.selected_guideline_id != null;
    },
  },
  watch: {
    show_guideline_modal(new_value) {
      if (new_value) {
        this.$nextTick(() => {
          this.$bvModal.show('globalGuideline');
          this.$root.$once("bv::modal::hidden", (event) => {
            if (event.type == "hidden" && event.componentId == "globalGuideline") {
              this.setSelectedGuidelineID({ type: null });
            }
          });
        })

      }
    }
  },
  methods: {
    ...adminMethods,
    ...levelMethods,
    ...guidelineMethods,
    ...activityMethods,
    mountChain(){
      this.setLoadingMessage("Fetching Core Data");
      console.log('Mount Chain', this.mount_tries)
      if(this.mount_tries == 3){
        return;
      }
    setTimeout(() => {

      if(localStorage.getItem('x_token') == null){
        this.mount_tries++;
        this.mountChain();
      }
      else if (this.core_loaded == false && this.is_core_loading == false) {
        let promises = [
          this.loadAllUsers(),
          this.loadCoreData(),
          this.loadNotifications(),
          this.loadLevels()
        ];
        this.setLoadingMessage("Fetching Users and Organisational Structure");
        Promise.all(promises)
          .then(() => {
            setTimeout(() => {
              // * Delay echo init, fixes unauthenticated console error
              this.initEcho();
            }, 1000);

            //* set the default milestone id
            //this.systemDefaultMilestoneCheck();

            //* restore user selection to last selected user
            this.lastLoggedInUserCheck();
            //.then(()=>{
            if (this.core.user_levels != null && this.core.user_levels.length > 0) {
              //* take user to their assigned level
              let user_level_id = this.core.user_levels[0].id;
              this.setLoggedInUserLevel(user_level_id).then(() => {
                let level_promises = [
                  this.loadCompanyMilestones(this.company_id),
                  this.loadMenuUsers(this.selected_level.id)
                ]
                Promise.all(level_promises)
                .then(() => {
                  this.restoreLastSelectedUser().then(() => {
                    this.setCoreLoaded(true);
                  });
                });
              });
            } else {
              //* take user to first level as fallback
              if (this.levels.length > 0) {
                let user_level_id = this.levels[0].id;
                this.setLoggedInUserLevel(user_level_id).then(() => {
                  this.setCoreLoaded(true);
                });
              }
            }
            //})
          });
      }
    }, 1500)
    },
    setLoggedInUserLevel(level_id) {
      return new Promise(resolve => {
        //* check if level is available in localStorage
        let saved_level = window.localStorage.getItem('level_id');
        let level = -1;
        if (saved_level) {
          level = this.levels.find(lvl => lvl.id == Number(saved_level));
        }
        else {
          level = this.levels.find(lvl => lvl.id == level_id);
        }
        //* set the level if the level object is valid
        if (level) {
          // valid level
          this.selectLevel(level)
            .then(() => { resolve(); })
        }
        else {
          // default to first level if the cached level doesn't exist
          this.selectLevel(this.levels[0])
            .then(() => { resolve(); })
        }
      })
    },
    isUserValidInMenu(id) {
      //eslint-disable-next-line
      for (const [key, value] of Object.entries(this.menu_users)) {
        let item = this.menu_users[key].find(p => p.id == id);
        if (item) {
          return true;
        }
      }
      return false;
    },
    restoreLastSelectedUser() {
      return new Promise(resolve => {
        let ls = window.localStorage;
        let user_id = ls.getItem('last_selected_user_id');
        let job_key = null;
        //if(user_id == null){
        //    user_id = this.core.id;
        //}
        if (this.isUserValidInMenu(user_id) == false) {
          //* dont continue with user selection
          resolve();
          return;
        }
        if (Number(user_id) != this.core.id) {
          //eslint-disable-next-line
          for (const [key, value] of Object.entries(this.menu_users)) {
            let item = this.menu_users[key].find(p => p.id == user_id);
            if (item && item.jobs.length > 0) {
              job_key = item.jobs[0].title;
              break;
            }
            else {
              resolve();
              return;
            }
          }
        }
        let payload = {
          id: user_id,
          job_key: job_key != null ? job_key : this.core.job_title,
        }
        this.setSelectedUser(payload);
        this.$nextTick(() => {
          resolve();
        })
      })

    },
    lastLoggedInUserCheck() {
      let ls = window.localStorage
      //* removed auto selecting logged in user if no local storage value
      let current_id = this.core.id;
      let last_id = ls.getItem('last_logged_in_id');
      if (last_id != current_id) {
        ls.setItem('last_logged_in_id', current_id);
        ls.removeItem('level_id');
        ls.removeItem('objective_id');
        ls.removeItem('milestone_id');
      }
    },
    initEcho() {
      //connect echo if not already connected 
      this.$echoManager.connectEcho();
      setTimeout(() => {
        this.$echoManager.refreshToken();
        this.$echoManager.subscribeToNotifications('user-channel.' + this.core.id, (data) => {
          console.log('NOTIFICATION', data);
          switch (data.type) {

            case 'unread_chat': {
              if (data.room_type == 'level') {
                this.setMessageIconForLevel(data.user_id);
              }
              else if (data.room_type == 'user') {
                this.setMessageIconForUser(data.room_type_id);
              }
              break;
            }
            case 'document_assignment': {
              this.loadNotifications();
              break;
            }
            case 'key_action_assigned': {
              // if (Notification.permission === 'granted') {
              //     const notification = new Notification('TEST', {
              //         body: 'Some text',
              //         //icon: data.icon // If you want to use an icon
              //     });
              //     notification.onclick = () => {
              //       // Do something when the notification is clicked 
              //       console.log('Notification clicked!');
              //     };
              // } else {
              //     // Inform the user they need to grant permission
              // }

              //-----//

              //                   if (!("Notification" in window)) {
              //   // Check if the browser supports notifications
              //   alert("This browser does not support desktop notification");
              // } else if (Notification.permission === "granted") {
              //   // Check whether notification permissions have already been granted;
              //   // if so, create a notification
              //   //eslint-disable-next-line
              //   const notification = new Notification("Hi there!");
              //   // …
              // } else if (Notification.permission !== "denied") {
              //   // We need to ask the user for permission
              //   Notification.requestPermission().then((permission) => {
              //     // If the user accepts, let's create a notification
              //     if (permission === "granted") {
              //       //eslint-disable-next-line
              //       const notification = new Notification("Hi there!");
              //       // …
              //     }
              //   });
              // }
              this.loadNotifications();
              break;
            }
            default: {
              console.warn('unhandled type', data.type);
            }
          }
        });
        this.$echoManager.subscribeToPresenceChannel('client-channel.' + 15, {
          onHere: (users) => {
            console.log('NOTIF - Users currently on the channel:', users);
          },
          onJoining: (user) => {
            console.log('NOTIF - User joining:', user);
            let payload = {
              state: true,
              user_id: user.id,
            }
            this.setUserOnlineState(payload);
          },
          onLeaving: (user) => {
            console.log('NOTIF - User leaving:', user);
            let payload = {
              state: false,
              user_id: user.id,
            }
            this.setUserOnlineState(payload);
          }
        });
      }, 800);
    },
    systemDefaultMilestoneCheck() {
      if (this.core.default_milestone != null) {
        this.$store.commit('activity/setMilestone', this.core.default_milestone);
        this.$store.commit('activity/setPinnedMilestone', this.core.default_milestone.id);
      }
    }
  },
  mounted() {
    this.mountChain();
  }
};
</script>

<template>
  <div>
    <Vertical class="vertical_layout_class" v-if="hasCoreLoaded && layoutType === 'vertical'" :layout="layoutType"
      :style="layoutWidth == '1920px' ? 'display: flex ;justify-content: center' : ''">
      <slot />
    </Vertical>

    <Horizontal v-if="hasCoreLoaded && layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>

    <div v-if="hasCoreLoaded == false">
      <Loading />
    </div>

    <!-- login overlay modal -- used for unauth error -->
    <!-- v-if controlled by boolean from admin vuex -->
    <LoginOverlay v-if="show_overlay_modal" />
    <GlobalGuidelineModal v-if="show_guideline_modal" />
  </div>
</template>
