<template>
    <b-overlay :show="is_loading" opacity="0.3">
            <div class="user-list-switch d-flex">
                <span>
                <!-- //*Search btns -->
                    <b-button v-if="show_search == false" @click="show_search = true;" style="padding: 0.125rem 0.25rem; width: 1.5rem;" variant="success" size="sm">
                        <font-awesome-icon icon="fa-solid fa-search" />
                    </b-button>
                    <b-button v-else @click="show_search = false;" style="padding: 0.125rem 0.25rem; width: 1.5rem;" variant="warning" size="sm">
                        <font-awesome-icon icon="fa-solid fa-times" />
                    </b-button>
                <!-- ---- -->

                <!-- //* Selected user btns -->
                    <b-button title="No employee selected" v-if="showClearButton == false" class="ms-1 me-1" style="padding: 0.125rem 0.25rem; width: 1.5rem;" disabled variant="outline-primary" size="sm">
                        <font-awesome-icon icon="fa-solid fa-user-alt-slash" />
                    </b-button>
                    <b-button title="Click to clear selected employee" v-if="showClearButton" @click="setSelectedUser(null);" class="ms-1 me-1" style="padding: 0.125rem 0.25rem; width: 1.5rem;" variant="primary" size="sm">
                        <font-awesome-icon icon="fa-solid fa-user" />
                    </b-button>

                    <b-button v-if="$hasPerm('people-manager')" title="Add User" @click="showAddUserModal" style="padding: 0.125rem 0.25rem; width: 1.5rem;" variant="outline-success" size="sm">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </b-button>
                <!-- --- -->
                </span>
                <span class="form-check form-switch mb-2 pt-1 ms-auto" style="cursor:pointer; font-size: 10px;">
                    <span style="float:right;">
                        <input class="form-check-input" :style="grouped ? 'cursor:pointer; background-color: transparent !important; color: #CBC3FF !important': 'cursor:pointer; background-color: #CBC3FF !important'" v-model="grouped" type="checkbox" id="usersToggle"/>
                        <label class="form-check-label" style="color: #CBC3FF !important" for="usersToggle">
                            Group by Job 
                        </label>
                    </span>
                </span>
            </div>
            <div v-bind:class="selected_user_id != null ? 'user-list-card custom-border menu_purple': 'user-list-card menu_purple'" style="border: 1px solid rgb(203, 195, 255); border-radius: 0.4em;">
                <div style="padding: 0.4rem; padding-bottom: 0px; padding-top: 0px;">
                    <div class="d-flex flex-wrap" style="width: 100%;">
                        <div class="me-2">
                            <h6 class="sub-heading-text mb-0 mt-2">
                                <span v-show="grouped">Job Titles ({{teams_count}})</span>
                                <span class="main_text_white" v-show="!grouped">People ({{team_memebers_count}})</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <b-collapse v-model="show_search" class="ps-1 pe-1">
                        <b-form-input autocomplete="off" v-model="search_text" placeholder="Search..." size="sm"></b-form-input>
                </b-collapse>
                <div v-if="show_table">
                    <b-alert class="m-2" variant="danger" :show="hasUsers == false">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />&nbsp;
                        No employees added.
                    </b-alert>
                    <webix-ui class="userListComponent" :config="ui_grouped" v-model="user_list"></webix-ui>
                </div>
            </div>
            <AddNewUserModal v-if="show_modal" />
    </b-overlay>
</template>

<script>
import AddNewUserModal from './addNewUserModal.vue'
import _ from 'lodash';
import {
    levelComputed, levelMethods,
    settingsComputed, settingsMethods,
    adminComputed, peopleComputed, peopleMethods,
    webixTableState,
    } from '@/state/helpers'
import Swal from 'sweetalert2';
import TableHelpers from '@/mixins/tableHelpers.js';
export default {
    mixins: [TableHelpers],
    components:{
        AddNewUserModal,
    },
    data:() => ({
        show_table: false,
        is_loading : false,
        list_type: 'grouped', //'list' or 'grouped //! old'
        //grouped: true,
        show_search: false,
        search_text: '',
        show_modal: false,
    }),
    watch:{
        user_list:{
            immediate: true,
            handler(){
                this.show_search = false;
                this.search_text = '';
                if(this.user_list.length > 0){
                    if(!this.show_table){
                        this.show_table = true;
                        this.$nextTick(()=>{
                            if(this.selected_user_id != null){
                                window.webix.$$('userLevelsTree').addCss(this.selected_user_id, 'selected_tree_item');
                                window.webix.$$('userLevelsTree').showItem(this.selected_user_id)
                            }
                        })
                    }
                    else{
                        this.is_loading = true;
                        this.show_table = false;
                        this.$nextTick(()=>{
                            this.show_table = true;
                            setTimeout(()=>{
                                this.is_loading = false;
                                if(this.selected_user_id != null){
                                    if(this.grouped){
                                        let index = 0;
                                        for(let key in this.menu_users){
                                            this.menu_users[key].forEach(( item => {
                                                if(item.id == this.selected_user_id){
                                                    //* oprn the folder that matches the job index - then select the user
                                                    window.webix.$$('userLevelsTree').open(index);
                                                    this.$nextTick(()=>{
                                                        window.webix.$$('userLevelsTree').addCss(this.selected_user_id, 'selected_tree_item');
                                                        window.webix.$$('userLevelsTree').showItem(this.selected_user_id)
                                                    })
                                                }
                                            }))
                                            index ++;
                                        }
                                    }
                                    else{
                                        window.webix.$$('userLevelsTree').addCss(this.selected_user_id, 'selected_tree_item');
                                        window.webix.$$('userLevelsTree').showItem(this.selected_user_id)
                                    }
                                }
                                
                            }, 200)
                        })
                    }
                    
                }
            }
        },
        show_search(){
            this.search_text = '';
        },
        search_text(newVal){
            if(window.webix.$$('userLevelsTree') != undefined){
                window.webix.$$('userLevelsTree').filter("#value#", newVal);
            }
        },
        selected_user_id:{
            handler(newValue, oldValue){
                if(this.current_level_id != null){
                    let tree = window.webix.$$('userLevelsTree');
                    if(tree){//* will be und
                        if(tree.exists(newValue)){
                            tree.addCss(newValue, 'selected_tree_item');
                        }
                    }
                    if(oldValue && tree.exists(oldValue)){
                        tree.removeCss(oldValue, 'selected_tree_item')
                    }
                    /*if(window.webix.$$('userLevelsTree').exists)
                    window.webix.$$('userLevelsTree').addCss(newValue, 'selected_tree_item')
                    if(oldValue != undefined){
                        window.webix.$$('userLevelsTree').removeCss(oldValue, 'selected_tree_item')
                    }*/
                }
            }
        }
    },
    computed:{
        ...peopleComputed,
        ...settingsComputed,
        ...levelComputed,
        ...adminComputed,
        ...webixTableState,
        hasUsers(){
            return this.user_list.length > 0;
        },
        /*grouped:{
            get(){ return this.grouped; },
            set(value){
                if(value != this.grouped){
                    this.setGrouping(value)
                }
            }
        },*/
        grouped: {
            get() {
                return this.$store.state.userSettings.grouped;
            },
            set(grouped) {
                this.setGrouping(grouped);
        },
    },
        ui_grouped(){
            let el = this;
            return{
            id: 'userLevelsTree',
            css: this.grouped ? 'scroll-fix customTreeStyles' : 'fix_left_padding customTreeStyles',
            view: 'tree',
            data: this.user_list,
            //autoheight: true,
            maxHeight: 200,
            scroll: 'y',
            template(object, common){
                if(object.type == 'folder'){
                    return `<div class="normal-text d-flex">
                                <div>
                                    ${common.icon(object, common)}
                                </div>
                                <div class="text-truncate">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="folder" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="me-2 svg-inline--fa fa-folder"><path fill="currentColor" d="M512 144v288c0 26.5-21.5 48-48 48h-416C21.5 480 0 458.5 0 432v-352C0 53.5 21.5 32 48 32h160l64 64h192C490.5 96 512 117.5 512 144z" class=""></path></svg>
                                    ${object.value}
                                </div>
                                <div class="ms-auto">
                                    (${object.members_length})
                                </div>
                            </div>`;
                }
                else{
                        let notification_icon = object.has_message == true
                        ? `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="message-envelope-icon has-chat-message svg-inline--fa fa-envelope"><path data-v-9910e250="" fill="currentColor" d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" class=""></path></svg>`
                        : `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="message-envelope-icon svg-inline--fa fa-envelope"><path data-v-9910e250="" fill="currentColor" d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z" class=""></path></svg>`
                        
                        let user_icon = object.is_online == true
                        ? `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="color: #32af58;" class="me-1 svg-inline--fa fa-user"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" class=""></path></svg>`
                        : `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="me-1 svg-inline--fa fa-user"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" class=""></path></svg>`

                        return common.icon(object, common) + `<span class="normal-text shift-left " id="user-list-${object.id}">
                        ${notification_icon}
                        ${user_icon}
                        ${object.value}</span>`
                    // else{
                    //     return common.icon(object, common) + `<span class="normal-text shift-left " id="user-list-${object.id}">
                    //     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="me-1 svg-inline--fa fa-user"><path fill="currentColor" d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" class=""></path></svg>
                    //     ${object.value}</span>`
                    // }

                }
            },
            on:{
                onItemClick(id){
                    //* check if any visible tables have unsaved changes
                    let has_unsaved_table_changes = el.$allTablesUnsavedChangesCheck();
                    if(has_unsaved_table_changes){
                        return false;
                    }
                    //* continue with level selection if no tables have unsaved changes


                    var item = this.getItem(id);
                    if(item.type === 'folder'){
                        if(item.open){
                            window.webix.$$('userLevelsTree').close(id);
                        }
                        else{
                            window.webix.$$('userLevelsTree').open(id);
                        }
                        
                    }
                    else{
                        //! note - need multipe $parent due to nested scope
                        // webix -> simplebar -> b-overlay
                        let el = this.$scope.$parent.$parent;
                        let payload = {
                            id: id,
                            job_key: item.key,
                        }
                        el.setSelectedUser(payload);
                    }
                }
            },
        }
        },
        team_memebers_count(){
            return this.user_list.length;
        },
        teams_count(){
            if(this.menu_users != null){
                return Object.keys(this.menu_users).length;
            }
            else{
                return '';
            }
            
        },
        user_list(){
            let data = [];
            if(this.menu_users != null){
                if(this.grouped == true){
                    let unsorted = [];
                    //* need to match job id to the job name
                    let index_id = 0;
                    for(let key in this.menu_users){
                        let group = {
                            type: 'folder',
                            open: false,
                            value: key,
                            data: [],
                            id: index_id,
                            members_length: 0,
                        }
                        this.menu_users[key].forEach(( item => {
                            group.data.push({
                                value: item.name,
                                id: item.id,
                                type: 'item',
                            })
                        }))
                        group.data = _.uniqBy(group.data, 'id');
                        group.data = _.orderBy(group.data, ['value']);
                        //group.value += ` (${group.data.length})`
                        group.members_length += group.data.length;
                        unsorted.push(group);
                        index_id++;
                    }
                    data = _.uniqBy(unsorted, 'id');
                    data = _.orderBy(data, ['value']);

                }
                else{
                    let users = [];
                    for(let key in this.menu_users){
                        this.menu_users[key].forEach( item => {
                            let user = {
                                value: item.name + ' ' + item.last_name,
                                id: item.id,
                                type: 'item',
                                key: key,
                                has_message: item.has_message != undefined ? item.has_message : false,
                                is_online: item.is_online != undefined ? item.is_online : false,
                            }
                            users.push(user);
                        })
                    }
                    data = _.uniqBy(users, 'id');
                    data = _.sortBy(data, o => o.value);
                }
            }
            //console.log(this.menu_users, data);
            return data;
        },
        isParent() {
            var parent = false;
            if (this.selected_level != null) {
                if (this.selected_level.parent_id == null) {
                    parent = true;
                }
            }
            return parent;
        },
        showClearButton(){
            if(this.selected_user_id != null && this.selected_user_id != undefined){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        ...settingsMethods,
        levelChanged(){
            this.loadUsers();
        },
        loadUsers(){
            //* refresh users table - remove selected user
            if(this.show_modal){
                this.show_modal = false;
            }
            this.show_table = false;
            this.setSelectedUser(null);
            let id = this.selected_level.id;
            //let id = this.isParent ? -1 : this.selected_level.id;
            if(id != -1){
                this.loadMenuUsers(id)
                .then(()=>{
                    this.show_table = true;
                })
            }
        },
        navigateToPeople(){
            if(this.$route.path !== '/new-user'){
                this.$router.push({path: '/new-user'});
            }
        },
        selectSignedInUserID(){
            let user_id = this.core.id;
            this.setSelectedUser(user_id);
        },
        forceSelectUser(id){
            let payload = {
                id: id,
                job_key: '',
            }
            this.setSelectedUser(payload);
            
        },
        showAddUserModal(){
            //check if no tables are in edit mode (webixTableState store)
            if(this.is_editing_table){
                Swal.fire('Please close any tables that are in edit mode before adding a new user');
                return;
            }
            this.show_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('newUserModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.componentId == "newUserModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_modal = false;
                        //* reset payload
                        //this.modal_payload = null;
                    }
                });
            })
        },
    },
    mounted(){
        if(this.menu_users == null && this.selected_level != null){
            //let id = this.isParent ? -1 : this.selected_level.id;
            let id = this.selected_level.id;
            this.loadMenuUsers(id)
            .then(()=>{
                this.show_table = true;
                this.$nextTick(()=>{
                    if(this.selected_user_id != null){
                        window.webix.$$('userLevelsTree').addCss(this.selected_user_id, 'selected_tree_item');
                    }
                })
            })
        }
        //this.$eventHub.$on('newLevelSelected2', this.handleNewLevel)
        this.$eventHub.$on('level-changed', this.levelChanged);
        this.$eventHub.$on('savedNewUser', this.loadUsers);
        this.$eventHub.$on('selectedHomeButton', this.selectSignedInUserID);
        this.$eventHub.$on('forceSelectNewUser', this.forceSelectUser);
        
    },
    beforeDestroy(){
        //let state = window.webix.$$('userLevelsTree').getState();
        //console.log("User list",state);
        this.$eventHub.$off('level-changed');
        this.$eventHub.$off('savedNewUser');
        this.$eventHub.$off('selectedHomeButton');
        this.$eventHub.$off('forceSelectNewUser');
        //* fix console error when hot reloading 
        if(window.webix.$$('userLevelsTree') != undefined){
            window.webix.$$('userLevelsTree').destructor();  
        }
    }
}
</script>

<style>

.webix_tree_item:hover{
    cursor: pointer;
}
.user-list-card{
    margin-left: 1rem;
    margin-right: 1rem;
}
.user-list-switch{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.8rem;
}
.fix_left_padding{
    /* margin-left: -10px; */
    border-radius: 0.4em;
    background-color: transparent;
}
.scroll-fix{
    height: unset !important;
}
.custom-border{
    border: 2px solid #556ee6;
    background-color: #dcedfc;
}

.userListComponent .webix_tree_leaves{
    width: 100%;
    padding-right: 0px !important;
}

.shift-left{
    margin-left: -1rem;
}
.message-envelope-icon{
    margin-bottom: -1px;
    color:#ffffff00;
}
.has-chat-message{
    color: #6279e983;
}
.message-envelope-icon:hover{
    color:#536ced !important;
    scale: 1.1;
    transition: ease-in 0.1s all;
}
</style>