<template>
    <div class="p-3 pt-0 pb-0 w-100" style="">
        <div style="min-width: 100%;border-bottom: 1px solid #97809F; padding: 4px">
            <span>
                <img
                class="rounded-circle header-profile-user custom-profile-image"
                :src="getProfileImage"
                alt=""
                />
                <span class="m-2" style="color: white !important">{{ getProfileName }}</span>
               
            </span>
            <b-dropdown
                v-if="core != null"
                right
                variant="black"
                toggle-class="nav-toggle-class"
                menu-class="nav-menu-class"
                no-caret
                >
                <template #button-content class="d-flex m-2" style="justify-content: space-between;">
<!--                     
                    <span class="m-2"><i class="arrow down ms-1 m-2" style="height: 10px !important; width: 10px !important; vertical-align: middle;"></i></span> -->
                   
                    
                    <!-- <span class="d-none d-xl-inline-block ms-1">{{ returnName }}</span> -->
                    <!-- <font-awesome-icon class="fa-fw" icon="fa-caret-down"/> -->
                </template>
                <!-- item-->
                <b-dropdown-item  @click="$router.push({name: 'testzone'});">
                    Role
                </b-dropdown-item>
            </b-dropdown>
        </div>
        
    </div>
</template>

<script>
import { activityComputed, activityMethods, adminComputed } from '@/state/helpers'
export default {
name: "notificationMenu",
components:{
   
},
props: {

},
mounted(){
    //this.setLoading(false);
},
data: () => ({
}),
methods:{
    ...activityMethods,
},

computed:{
    ...activityComputed,
    ...adminComputed,
    getProfileImage(){
        return this.core.avatar_src;
    },
    getProfileName() {
        return this.core.full_name
    }
},
}

</script>

<style>
.decorated_select {
text-align: center; 
background-color: #3A0D4A !important;
color: #CBC3FF !important;
border-radius: 0.5rem; 
font-size: 0.7rem !important;
width: 100%
}
.nav-toggle-class {
text-align: center; 
/* background-color: #3A0D4A !important; */
color: #CBC3FF !important;
padding: 1px !important;
padding-bottom: 4px !important;
border-radius: 0.5rem; 
font-size: 0.7rem !important;
width: 100%
}
.nav-menu-class {
color: #CBC3FF !important;
margin-left: 3px;
/* padding-top: 20px;
margin-top: -20px; */
/* overflow: hidden;
z-index: 1; */
padding: 0px;
margin-top: 0px !important;
/* width: 302px; */
background-color: #736BA5;
border: 1px solid #CBC3FF
}

/* .decorated_select:hover {
border: 1px solid #CBC3FF
} */
.decorated_select:focus {
border: 1px solid #CBC3FF
}
.decorated_select .dropdown-toggle {
border: 1px solid #CBC3FF !important;
}
.decorated_select .btn-secondary:focus {
box-shadow: none;
}

.custom-profile-image {
    border: 2px solid #50A5F1; 
    width: 40px;
    height: 40px
}
.custom-profile-image:hover {
    border: 2px solid #50A5F1;  
}

</style>
