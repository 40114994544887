<template>
    <!--<span class="pt-4 font-size-16 header-item"> This is a placeholder for milestone text</span>-->
    <div class="btn-group p-3 pt-0 w-100" >
        <div class="d-flex w-100" style="flex-direction: column;">
            <span class="m-1 btn-group">
                <span class="w-100">
                <span class="m-1 main_text_white">
                    Select Year
                </span>
                    <b-dropdown id="yearTypeDropdown" toggle-class='date_toggle_class' no-caret menu-class="custom-date-menu-class" class="decorated_select">
                            <template #button-content>
                                <div v-if="company_milestones != null && hasMilestoneSelected" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                    <div style="width: 30px"></div>
                                    <div class="" style="">
                                        {{ selectedMilestoneName }}
                                    </div>
                                    <div class="" style="width: 35px; margin-top: 3px !important;">
                                        <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #CBC3FF" />
                                    </div>
                                </div>
                                
                                <span v-if="!hasMilestoneSelected">{{selectedMilestoneName}}</span>
                            </template>
                            <!-- <span class="ms-2">Select Milestone Year </span>
                            <div class="p-1">
                                <div class="d-flex flex-wrap" style="width: 360px;">
                                    <b-button :title="getMilestoneString(milestone)" v-b-tooltip.hover v-for="milestone in available_milestones" :key="milestone.id" @click="setPinnedMilestone(milestone.id)" :variant="pinned_milestone_id == milestone.id ? 'primary':'outline-primary'" class=" w-xs m-1" size="sm">
                                        {{ getMilestoneYear(milestone) }}
                                    </b-button>

                                    <b-button @click="showAddMilestoneModal" size="sm" class="m-1" variant="success">
                                        <font-awesome-icon class="" icon="fa-plus"/>
                                        
                                    </b-button>
                                </div>
                            </div> -->
                            
                            <b-dropdown-item class="year_select_item" v-for="milestone in available_milestones" :key="milestone.id" @click="setPinnedMilestone(milestone.id)" :class="pinned_milestone_id == milestone.id ? 'bg-primary bg-soft':''">
                                <div class="d-flex w-100 customItem">
                                    <span>
                                        {{ getMilestoneString(milestone) }}
                                    </span>
                                    <span v-if="canUserEdit" class="ms-auto" @click="showAddMilestoneModal(milestone, $event)">
                                            <font-awesome-icon class="me-1 fa-fw" icon="fa-pencil"/>
                                    </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="canUserEdit" class="year_select_item" variant="success" @click="showAddMilestoneModal(null)">
                                <font-awesome-icon class="me-1" icon="fa-plus"/>
                                New Year
                            </b-dropdown-item>
                            <b-dropdown-item class="year_select_item" v-show="hasMilestoneSelected" variant="danger" @click="setPinnedMilestone(null)">
                                <font-awesome-icon class="me-1" icon="fa-times"/>
                                Clear Selected Year
                            </b-dropdown-item>
                            <b-dropdown-text v-show="available_milestones.length == 0" style="text-align: center; min-height: 27.5px;" class="pt-3 ps-2 pe-2">
                                <b-alert show variant="danger">
                                    <font-awesome-icon class="me-1" icon=" fa-info" />
                                    The Company has no year selected
                                </b-alert>
                                
                            </b-dropdown-text>
                            <MilestoneEditModal v-if="show_modal" :milestone_item="milestone_modal_payload"/>
                    </b-dropdown>
                </span>
            </span>
        </div>
    </div>

</template>

<script>
//eslint-disable-next-line
import _ from 'lodash'
import { activityComputed, activityMethods, levelComputed, adminComputed, } from '@/state/helpers'
import tableHelpers from '@/mixins/tableHelpers.js';
//import radialProgressBar from '../objectives/components/tiles/radialProgressBar.vue';
import MilestoneEditModal from '../objectives/components/misc/MilestoneEditModal.vue';
export default {
    mixins: [tableHelpers],
    components:{
        MilestoneEditModal,
        //radialProgressBar
    },

    data:() => ({
        show_modal: false,
        milestone_modal_payload: null,
    }),
    watch:{
        company_id(newValue){
            this.setPinnedMilestone(null)
            if(newValue != null ){
                this.loadCompanyMilestones(newValue)
            }
        }
    },
    computed:{
        ...activityComputed,
        ...levelComputed,
        ...adminComputed,
        canUserEdit(){
            return this.$hasPerm('milestones-manager');
        },
        selectedMilestoneProgress(){
            if(this.pinned_milestone_id == -1){
                return 0
            }
            else{
                let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
                if(!milestone){
                    return 0;
                }
                else{
                    return milestone.progress;
                }
            }
        },
        selectedMilestoneName(){
            if(this.pinned_milestone_id == -1){
                return 'No year selected';
            }
            else{
                let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
                if(!milestone){
                    return '';
                }
                else{
                    if(milestone.name != null) {
                        let till = Number(milestone.year) + 1
                        return `${milestone.year} - ${till}`;
                    } else {
                        return `${milestone.year}`;
                    }
                    
                }
            }
        },
        canShowComponent(){
            let milestone = this.company_milestones.find( item => item.id == this.pinned_milestone_id);
            return milestone ? true : false;
        },
        hasMilestoneSelected(){
            return this.pinned_milestone_id == -1 ? false : true;
        },
        available_milestones(){
            if(this.company_milestones == null){
                //* milestones not loaded yet - early exit
                return [];
            }

            let data = [];
            //if(this.activity_data != null){
                data = this.company_milestones.map( item => {
                    return {
                        id: item.id,
                        name: item.name,
                        year: item.year,
                    }
                })
            //}
            return _.orderBy(data, ['year', 'name']);
        }
    },
    methods:{
        ...activityMethods,
        setMilestoneEvent: _.debounce(function(milestone_id){
            this.setPinnedMilestone(milestone_id);
        }, 50),
        showAddMilestoneModal(milestone_payload, event = null){
            if(event != null){
                event.stopPropagation();
            }
            this.milestone_modal_payload = milestone_payload;
            this.show_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('milestoneModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "milestoneModal") {
                        this.show_modal = false;
                    }
                });
            })
            
        },
        getMilestoneString(milestone_object){
            let till = Number(milestone_object.year) + 1
            return `${milestone_object.year} - ${till}`;    
        },
        getMilestoneYear(milestone_object){
                return `${milestone_object.year}`;    
        },
        setPinnedMilestone(milestone_id){
            // check for unsaved data first
            const has_unsaved_changes = this.$allTablesUnsavedChangesCheck();
            if(has_unsaved_changes){
                //* stop here if unsaved changes are found in webix tables
                return;
            }
            let must_clear_selection = milestone_id == null || this.pinned_milestone_id == milestone_id;
            if(must_clear_selection){
                //* need to unpin milestone
                this.setPinnedMilestoneId(-1);
                this.setSelectedMilestone(null);
                //alert('What are you doing?')
                //this.setSelectedObjective(null);
                //this.loadActivityData(this.selected_level.id)
            }
            else{
                let milestone = this.available_milestones.find( item => item.id == milestone_id);
                if(milestone){
                    // let old_milestone_id = _.clone(this.pinned_milestone_id)
                    this.setPinnedMilestoneId(milestone_id);
                    this.setSelectedMilestone(milestone);
                    //this.setSelectedObjective(null);
                    //if(old_milestone_id != milestone.id){
                    //    this.loadActivityData(this.selected_level.id)
                    //}
                    
                }
            }
        },
        restoreCurrentYearSelection(){
            let current_year = new Date().getFullYear().toString();
            let current_year_milestone = _.find(this.company_milestones, {year:current_year});
            let last_selected_quarter = window.localStorage.last_selected_quarter;

            if(current_year_milestone){
                //* only set quarter if a year was restored from localStorage
                if(last_selected_quarter){
                    this.setObjectiveQuarter(last_selected_quarter)
                }
                this.setPinnedMilestone(current_year_milestone.id);
            }
            else{
                //* need to save a milestone year for current year if none exists
                alert('Need to create milestone for current year')
            }
        }
    },
    mounted(){
        let has_company_level_id = this.company_id != null;
        let has_no_milestones_loaded = this.company_milestones == null;

        if(has_company_level_id && has_no_milestones_loaded){
            this.loadCompanyMilestones(this.company_id).then(()=>{
                //* Get and set the milestone for the current year ( replaced core.default_milestone check)
                this.restoreCurrentYearSelection();
            })
        }
        this.$eventHub.$on('selectedNewMilestone', this.setMilestoneEvent);
    },
    beforeDestroy(){
        this.$eventHub.$off('selectedNewMilestone');
    }
}
</script>

<style>

/* .milestone-dropdown-item > a{
    padding-left: 1rem;
    padding-right: 0.5rem;
}
.milestone-dropdown-item > a > div > .ms-auto {
    color: rgba(255, 255, 255, 0);
}
.milestone-dropdown-item:hover > a > div > .ms-auto > svg {
    color: black;
    transition: ease-in 0.05s all;
}
.milestone-dropdown-item:hover > a > div > .ms-auto:hover > svg {
    scale: 1.2;
    color: rgb(0, 98, 255);
    transition: ease-in 0.05s all;
} */

.customItem {
    color: white !important
}

.decorated_select {
text-align: center; 
background-color: #3A0D4A !important;
color: #CBC3FF !important;
border-radius: 0.5rem; 
font-size: 0.7rem !important;
width: 100%;
min-height: 28px
}
.date_toggle_class {
text-align: center; 
background-color: #3A0D4A !important;
color: #CBC3FF !important;
border: 1px solid #CBC3FF;
padding: 1px !important;
padding-bottom: 4px !important;
border-radius: 0.5rem; 
font-size: 0.75rem !important;
width: 100%
}
.custom-date-menu-class {
color: #CBC3FF !important;
margin-left: 3px;
/* padding-top: 20px;
margin-top: -20px; */
/* overflow: hidden;
z-index: 1; */
padding: 0px;
margin-top: 0px !important;
width: 302px;
background-color: #736BA5;
border: 1px solid #CBC3FF
}

/* .decorated_select:hover {
border: 1px solid #CBC3FF
} */
.decorated_select:focus {
border: 1px solid #CBC3FF
}
.decorated_select .dropdown-toggle {
border: 1px solid #CBC3FF !important;
}
.decorated_select .btn-secondary:focus {
box-shadow: none;
}
.arrow-grey {
border: 10px solid #CBC3FF;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
}
</style>