<template>
        <div class="btn-group pt-0 w-100" role="group">
            <div class="w-100" style="display: flex; flex-direction: column;">
                <span class="btn-group ps-1 pe-1 d-flex" style="justify-content: center;">
                    <span class="cursor-pointer">
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 1" data-content="Q1" :style="objective_viewing_quarter == 1 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(1)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 1" data-content="Q1" :style="objective_viewing_quarter == 1 ? 'color: white' : ''" @click="setViewingQuarter(1)" ></i>
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 2" data-content="Q2" :style="objective_viewing_quarter == 2 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(2)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 2" data-content="Q2" :style="objective_viewing_quarter == 2 ? 'color: white' : ''" @click="setViewingQuarter(2)" ></i>
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 3" data-content="Q3" :style="objective_viewing_quarter == 3 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(3)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 3" data-content="Q3" :style="objective_viewing_quarter == 3 ? 'color: white' : ''" @click="setViewingQuarter(3)" ></i>
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 4" data-content="Q4" :style="objective_viewing_quarter == 4 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(4)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 4" data-content="Q4" :style="objective_viewing_quarter == 4 ? 'color: white' : ''" @click="setViewingQuarter(4)" ></i>
                    </span>
                    <span class="split_values" style="margin-left: 0.3rem">
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 5" data-content="H1" :style="objective_viewing_quarter == 5 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(5)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 5" data-content="H1" :style="objective_viewing_quarter == 5 ? 'color: white' : ''" @click="setViewingQuarter(5)" ></i>
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 6" data-content="H2" :style="objective_viewing_quarter == 6 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(6)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 6" data-content="H2" :style="objective_viewing_quarter == 6 ? 'color: white' : ''" @click="setViewingQuarter(6)" ></i>
                    
                    </span>
                    <span class="split_values" style="margin-left: 0.3rem">
                        <i class="bi bi-hexagon menu-hex default" v-show="objective_viewing_quarter != 7" data-content="YR" :style="objective_viewing_quarter == 7 ? 'color: #D0CCE680' : ''" @click="setViewingQuarter(7)" ></i>
                        <i class="bi bi-hexagon menu-hex selected" v-show="objective_viewing_quarter == 7" data-content="YR" :style="objective_viewing_quarter == 7 ? 'color: white' : ''" @click="setViewingQuarter(7)" ></i>
                    </span>
                </span>
            </div>
        </div>
</template>

<script>
import { activityComputed, activityMethods } from '@/state/helpers'
export default {
    name: "dateFilter",
    components:{
       
    },
    props: {

    },
    mounted(){
        //this.setLoading(false);
    },
    data: () => ({
        selected_filter: 'YR',
        selected_year_filter: 'Calendar',
        selected_year: 2024,
        selected_year_type: 1,
        year_options: [{ value: 2024, text: '2024-2025' },{ value: 2023, text: '2023-2024' },{ value: 2022, text: '2022-2023' }],
        year_type_options: [{ value: 1, text: 'Calendar Year ' },{ value: 2, text: 'Financial Year' },{ value: 3, text: 'Rolling Year' }] 
    }),
    methods:{
        ...activityMethods,
        setFilter(value) {
            this.selected_filter = value
        },
        setYearFilter(value) {
            this.selected_year_filter = value
        },
        setViewingQuarter(quarter){
            //this.$plausible.trackEvent('Changed Viewing Period');
            this.setObjectiveQuarter(quarter)
            //this.$refs.datepickerFilterDropdown.hide();
            this.filterObjectivesByQuarter();
        }
    },

    computed:{
        ...activityComputed,
        getYear() {
            let value = ''
            this.year_options.forEach(year => {
                if(year.value == this.selected_year) {
                    value = year.text
                }
            })
            return value
        },
        getYearType() {
            let value = ''
            this.year_type_options.forEach(type => {
                if(type.value == this.selected_year_type) {
                    value = type.text
                }
            })
            return value
        }
    },
}

</script>

<style>
.selected_date_style {
    border: 2px solid #50A5F1
}
.selected_date_style:focus {
    border: 2px solid #50A5F1
}

.quarter_rounded {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 0.6rem !important;
    font-weight: 500;
    border-radius: 50% !important;
}

.year_rounded {
    width: 6rem;
    height: 1.7rem;
    font-size: 0.7rem !important;
    border-radius: 15px !important;
}

.decorated_select {
    text-align: center; 
    background-color: #3A0D4A !important;
    color: #CBC3FF !important;
    border-radius: 0.5rem; 
    font-size: 0.7rem !important;
    width: 100%
}
.date_toggle_class {
    text-align: center; 
    background-color: #3A0D4A !important;
    color: #CBC3FF !important;
    border: 1px solid #CBC3FF;
    padding: 1px !important;
    padding-bottom: 4px !important;
    border-radius: 0.5rem; 
    font-size: 0.75rem !important;
    width: 100%
}
.custom-date-menu-class {
    color: #CBC3FF !important;
    margin-left: 3px;
    /* padding-top: 20px;
    margin-top: -20px; */
    /* overflow: hidden;
    z-index: 1; */
    padding: 0px;
    margin-top: 0px !important;
    width: 302px;
    background-color: #736BA5;
    border: 1px solid #CBC3FF
}

/* .decorated_select:hover {
    border: 1px solid #CBC3FF
} */
.decorated_select:focus {
    border: 1px solid #CBC3FF
}
.decorated_select .dropdown-toggle {
    border: 1px solid #CBC3FF !important;
}
.decorated_select .btn-secondary:focus {
    box-shadow: none;
}
.arrow-grey {
    border: 10px solid #CBC3FF;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.split_values {
    margin-left: 0.1rem;
}
.menu-hex {
    margin: 0.1rem;
}
@media(min-width: 2200px) {
    .split_values {
        margin-left: 2rem;
    }
    .menu-hex {
        margin: 0.2rem 
    }
}

@media (min-resolution: 1.8dppx) { 
    .split_values {
        margin-left: 0rem !important;
    }
    .menu-hex {
        margin: 0rem !important
    }
}


.default::before {
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  content: attr(data-content);
  padding-top: 6px;
  padding-right: 4px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%233A0D4A' stroke='%23736BA5' stroke-width='0.5' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  font-style: initial;
  width: 36px;
  height: 36px
}
.selected::before {
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  content: attr(data-content);
  padding-top: 6px;
  padding-right: 4px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23736BA5' stroke='%2350A5F1' stroke-width='0.8' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  font-style: initial;
  width: 36px;
  height: 36px
}
</style>
