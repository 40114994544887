<template>
    <div class="user-list-card set-grey-border menu_purple">
        <div style="padding: 0.4rem; padding-bottom: 0px; padding-top: 0px;">
            <div class="d-flex flex-wrap" style="width: 100%;">
                <div class="me-2">
                    <h6 class="sub-heading-text mb-0 mt-2 main_text_white">
                        <span>Teams</span><span v-if="list_data.length != 0"> ({{ list_data.length }})</span>
                    </h6>
                </div>
            </div>
        </div>
        <span v-show="is_loading == false && list_data.length == 0" class="text-secondary px-1">
            No Teams Found
        </span>
        <webix-ui class="userListComponent" :config="ui" v-model="list_data"></webix-ui>
        
        <b-overlay no-wrap :show="is_loading" opacity="0.3"></b-overlay>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { levelMethods, levelComputed } from '@/state/helpers';
export default {
    data:() => ({
        is_loading: false,
    }),
    watch:{
        //* watch variables that need to load data
        current_level_id(){
            this.is_loading = true;
            this.fetchData();
        },
        selected_user_id(){
            this.is_loading = true;
            this.fetchData();
        },

        //* ----------------
        list_data(){
            const is_id_in_array = this.list_data.some(item => item.id == this.selected_team_id);
            if(!is_id_in_array){
                this.selectLevelTeam(-1);
            }

        }
    },
    computed:{
        ...levelComputed,
        list_data(){
            return this.level_teams != null
                ? this.level_teams : [];
        },
        ui(){
            let el = this;
            return {
                id: 'teamsList',
                view: 'tree',
                css: 'scroll-fix customTreeStyles',
                on:{
                    onItemClick(id){
                        el.handleTeamSelect(id);
                        //el.selectLevelTeam(id);
                    }
                },
                //eslint-disable-next-line
                template(object, common){
                    let class_names = 'normal_text';
                    if(object.id == el.selected_team_id){
                        class_names += 'text-primary font-weight-bold'
                    }
                    return `
                    <div class="${class_names}">
                    ${object.name}
                    </div>
                    `;
                },
            }
        }
    },
    methods:{
        ...levelMethods,
        fetchData: debounce( function (){
            let params = {
                level_id: this.current_level_id,
            }
            if(this.selected_user_id != null){
                params['user_id'] = this.selected_user_id;
            }

            this.loadLevelTeams(params).then(()=>{
                this.is_loading = false;
            })
        }, 100),
        handleTeamSelect(id){
            if(id == this.selected_team_id){
                this.selectLevelTeam(-1);
            }
            else{
                this.selectLevelTeam(id);
            }
            //this.selectLevelTeam(id);
            this.$nextTick(()=>{
                window.webix.$$('teamsList').refresh();
            })
        },
    },
    mounted(){
        this.fetchData();
    },
}
</script>

<style>
.normal_text {
    font-size: 0.7rem;
}

</style>