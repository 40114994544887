<template>
    <div class="btn-group p-3 pt-0 pb-0 w-100" role="group">
        <div class="w-100" style="display: flex; flex-direction: column;">

            <span class="btn-group m-1">
                <span class="w-100">
                    <span class="m-1 main_text_white">
                        Select Year Type
                    </span>
                    <!-- <b-form-select class="decorated_select" v-model="selected_year_type" :options="year_type_options"></b-form-select> -->
                    <b-dropdown id="yearTypeDropdown" toggle-class='date_toggle_class' no-caret menu-class="custom-date-menu-class" class="decorated_select" :text="getYearType">              
                        <b-dropdown-item class="customItem"  style="color: white !important"
                            @click="selected_year_type(option)"
                            v-for="option in year_type_options"
                            :key="option.value"
                        >
                        <div class="d-flex">
                            <span>
                                {{option.text}}
                            </span>     
                        </div>
                        </b-dropdown-item>
                        <template #button-content>
                            <div class="" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                                <div style="width: 30px"></div>
                                <div class="" style="">
                                    {{ getYearType }}
                                </div>
                                <div class="" style="width: 35px; margin-top: 5px !important;">
                                    <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #CBC3FF" />
                                </div>
                            </div>
                        </template>
                    </b-dropdown>
                </span>        
            </span>
        </div>
    </div>
</template>

<script>
import { activityComputed, activityMethods } from '@/state/helpers'
export default {
name: "pinnedYearType",
components:{
   
},
props: {

},
mounted(){
    //this.setLoading(false);
},
data: () => ({
    selected_year_type: 1,
    year_type_options: [{ value: 1, text: 'Calendar Year ' },{ value: 2, text: 'Financial Year' },{ value: 3, text: 'Rolling Year' }] 
}),
methods:{
    ...activityMethods,
    setFilter(value) {
        this.selected_filter = value
    },
    setYearFilter(value) {
        this.selected_year_filter = value
    },
},

computed:{
    ...activityComputed,
    getYearType() {
        let value = ''
        this.year_type_options.forEach(type => {
            if(type.value == this.selected_year_type) {
                value = type.text
            }
        })
        return value
    }
},
}

</script>

<style>
.quarter_rounded {
width: 1.7rem;
height: 1.7rem;
font-size: 0.6rem !important;
font-weight: 500;
border-radius: 50% !important;
}

.year_rounded {
width: 6rem;
height: 1.7rem;
font-size: 0.7rem !important;
border-radius: 15px !important;
}

.decorated_select {
text-align: center; 
background-color: #3A0D4A !important;
color: #CBC3FF !important;
border-radius: 0.5rem; 
font-size: 0.7rem !important;
width: 100%
}
.date_toggle_class {
text-align: center; 
background-color: #3A0D4A !important;
color: #CBC3FF !important;
border: 1px solid #CBC3FF;
padding: 1px !important;
padding-bottom: 4px !important;
border-radius: 0.5rem; 
font-size: 0.75rem !important;
width: 100%
}
.custom-date-menu-class {
color: #CBC3FF !important;
margin-left: 3px;
/* padding-top: 20px;
margin-top: -20px; */
/* overflow: hidden;
z-index: 1; */
padding: 0px;
margin-top: 0px !important;
width: 98%;
background-color: rgb(136, 129, 179);
border: 1px solid #CBC3FF
}

/* .decorated_select:hover {
border: 1px solid #CBC3FF
} */
.custom-date-menu-class .dropdown-item:hover {
    background-color: #3A0D4A !important;
    color:white !important
}
.decorated_select:focus {
border: 1px solid #CBC3FF
}
.decorated_select .dropdown-toggle {
border: 1px solid #CBC3FF !important;
}
.decorated_select .btn-secondary:focus {
box-shadow: none;
}
.arrow-grey {
border: 10px solid #CBC3FF;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
}

</style>
