<!-- //* Container to hold the menu items, dropdown items and modal to add levels -->
<template>
    
    <b-overlay :show="is_loading || is_another_component_loading" opacity="0.3">
        <div class="user-list-switch d-flex">
            <span>
                <!-- //*Search btns -->
                <b-button
                    v-if="show_search == false"
                    @click="show_search = true"
                    style="padding: 0.125rem 0.25rem; width: 1.5rem;"
                    variant="success"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-solid  fa-search" />
                </b-button>
                <b-button
                    v-else
                    @click="show_search = false"
                    style="padding: 0.125rem 0.25rem; width: 1.5rem;"
                    variant="warning"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-solid  fa-times" />
                </b-button>
                <!-- ---- -->

                <!-- //* Selected user btns -->
                <b-button
                    @click="gotoHomeLevel"
                    title="Your Home"
                    class="ms-1"
                    style="padding: 0.125rem 0.25rem; width: 1.5rem;"
                    variant="primary"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-solid  fa-home" />
                </b-button>

                <GuidelineModalButton v-if="false" class="ms-1" guideline_key="level_menu"/>

                <!-- --- -->
                <b-button
                    v-b-toggle.sidebar-1
                    title="Open Chat"
                    class="ms-1 no-focus"
                    style="padding: 0.125rem 0.25rem; width: 1.5rem;"
                    :variant="isChatVisible ? 'primary' : 'outline-primary'"
                    size="sm"
                >
                    <font-awesome-icon icon="fa-solid  fa-comment" />
                </b-button>
                <!-- --- -->
            </span>
            <span
                v-if="$hasPerm('manage-structure')"
                class="form-check form-switch mb-2 pt-1 ms-auto"
                style="cursor:pointer; font-size: 10px;"
            >
                <span style="float:right;">
                    <input
                        class="form-check-input"
                        :style="drag_toggle ? 'cursor:pointer; background-color: transparent !important; color: #CBC3FF !important': 'cursor:pointer; background-color: #CBC3FF !important'"
                        v-model="drag_toggle"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                    />
                    <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        style="color: #CBC3FF !important"
                    >
                        Reorder Structure
                    </label>
                </span>
            </span>
        </div>
        <div
            style="margin: 1rem; margin-top: 0; border: 1px solid #CBC3FF; border-radius: 0.4em;"
            :class="
                allow_tree_selection
                    ? 'bg-primary bg-soft border border-primary'
                    : ''
            "
        >
            <div
                style="padding: 0.4rem; padding-bottom: 0px; padding-top: 0px; background-color: #3A0D4A; border-radius: 0.4em;"
            >
                <div class="d-flex flex-wrap" style="width: 100%; justify-content: space-between;">
                    <span class="me-2">
                        <div class="d-flex">
                        <h6 class="sub-heading-text main_text_white mb-0 mt-2">Organisational Structure</h6>
                        </div>
                    </span>
                    <span class="me-2 mt-1 mb-1" style="margin-left: 10px; margin-right: 0px !important">
                        <div class="d-flex">
                            
                        <b-button
                            @click="displayOrgChart"
                            title="Organisation Structure"
                            class="ms-1"
                            style="padding: 0.125rem 0.25rem; width: 1.5rem;"
                            size="sm"
                            variant="primary"
                        >
                        <font-awesome-icon icon="fa-solid  fa-sitemap" />
                        </b-button>
                        </div>
                    </span>
                </div>
                <div class="ms-2" v-if="enable_drag_mode">
                    <div class="font-size-10">
                        <strong
                            v-show="reposition.source == null"
                            class="text-danger"
                            >!</strong
                        >
                        Source:
                        <span class="text-primary text-truncate">{{
                            repositionSourceName
                        }}</span>
                    </div>
                    <div class="font-size-10">
                        <strong
                            v-show="
                                reposition.source != null &&
                                    reposition.target == null
                            "
                            class="text-danger"
                            >!</strong
                        >
                        Destination:
                        <span class="text-primary text-truncate">{{
                            repositionTargetName
                        }}</span>
                    </div>
                </div>
            </div>
            <!--//* --------- ADD LEVEL MODAL --- *// -->
            <b-modal
                id="newLevelModal"
                ref="newLevelModal"
                v-if="showAddModal"
                :title="modal_title"
            >
                <b-overlay :show="is_loading" opacity="0.3">
                    <b-alert show
                        >New levels need to be assigned in the permissions
                        screen</b-alert
                    >
                    <b-form @submit="handleSubmit">
                        <b-row class="mb-1">
                            <b-col cols="12">
                                <b-form-group label="Level Name">
                                    <b-form-input
                                        ref="levelNameInput"
                                        v-model="new_level.name"
                                        autofocus
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Lead User">
                                    <vue-typeahead-bootstrap
                                        ref="userSearch"
                                        :data="users"
                                        v-model="query"
                                        placeholder="Search name/email"
                                        showOnFocus
                                        @hit="selectedItemEvent"
                                    ></vue-typeahead-bootstrap>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-overlay>
                <template #modal-footer>
                    <div class="w-100">
                        <b-button
                            variant="light"
                            style="float:right"
                            @click="showAddModal = false"
                        >
                            Close
                        </b-button>

                        <b-button
                            variant="success"
                            style="float:right"
                            @click="saveLevel()"
                            class="me-2"
                        >
                            Save
                        </b-button>
                    </div>
                </template>
            </b-modal>
            <div>
                <div v-if="is_loading == false">
                    <!-- levels -->
                    <b-collapse v-model="show_search" class="ps-1 pe-1">
                        <b-form-input
                            autocomplete="off"
                            v-model="search_text"
                            placeholder="Search..."
                            size="sm"
                        ></b-form-input>
                    </b-collapse>
                    <simplebar style="height: auto">
                        <!-- <keep-alive include="level-menu, WebixTree, orgChartTree"> -->
                        <WebixTree
                            class="h-100"
                            :enable_drag_mode="enable_drag_mode"
                            :levels="levels"
                            @onRowDrag="handleDragEvent"
                            @levelContextAction="handleLevelAction"
                            @clickedLevel="clickedLevelEvent"
                        />
                    <!-- </keep-alive> -->
                    </simplebar>
                </div>
            </div>
        </div>

        <div>
            <TeamsMenu/>
        </div>

        <!-- <hr /> -->
        <UsersList />
        <b-overlay opacity="0.3"
            :show="is_loading_levels || selection_lock"
            no-wrap
        ></b-overlay>
    </b-overlay>
</template>

<script>
    import TeamsMenu from './side-nav/teams-menu.vue';
    import UsersList from "./users-level-list.vue";
    import GuidelineModalButton from '@/components/widgets/misc/guidelineModalButton.vue';
    //import MenuItem from "./collapse-menu.vue";
    import simplebar from "simplebar-vue";
    import {
        levelMethods,
        levelComputed,
        menuState,
        menuMethods,
        activityMethods,
        adminComputed,
        activityComputed,
        handbookComputed,
        profileComputed,
        profileMethods,
        peopleComputed,
        peopleMethods
    } from "@/state/helpers";
    import axios from "axios";
    //import VJstree from 'vue-jstree'
    //import Tree from 'vuejs-tree'
    import _ from "lodash";
    import Swal from "sweetalert2";
    import WebixTree from "./orgChartTree.vue";
    import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
    import TableHelpers from '@/mixins/tableHelpers.js';

    export default {
        name: 'level-menu',
        mixins: [TableHelpers],
        data: () => ({
            modified_lead_user: false,
            query: '',
            modal_title: "",
            //* search bar vars
            show_search: false,
            search_text: "",

            menu_created: false,
            menu_data: [], //hierarchy menu data
            depth: 1, // initial depth of first menu item
            expanded: true, // force top level menu item to always be expanded
            selected_level_id: null,
            base: process.env.VUE_APP_API_BASEURL,
            showAddModal: false,
            show_tree: true,
            is_loading: false,
            is_loading_levels: false,
            enable_drag_mode: false,
            drag_toggle: false,
            //* Post data for adding a level
            new_level: {
                id: -1,
                name: "",
                parent_id: "",
                type: "level",
            },

            must_select_user: false,
            new_selection_id: null, //* recieved from forceSelectLevel event, used as param for users tree
        }),
        watch: {
            drag_toggle() {
                this.toggleDrag();
            },
            selected_org_level:{
                handler(){
                    if(this.selected_org_level != null){
                        window.webix.$$('levelsTree').select(this.selected_org_level)
                        this.openTreeNode(window.webix.$$('levelsTree'), this.selected_org_level)
                        this.clickedLevelEvent(this.selected_org_level)
                    }
                }
            },

            menu_users(new_value) {
                if (new_value && this.must_select_user) {
                    this.$nextTick(() => {
                        this.$eventHub.$emit(
                            "forceSelectNewUser",
                            this.new_selection_id
                        );
                        this.must_select_user = false;
                        this.new_selection_id = null;
                    });
                }
            },
            level_users: {
                deep: true,
                immediate: true,
                handler: function() {
                    this.$nextTick(() => {
                        //* fix users dropdown data from being undefined on first load
                        const payload = this.level_users?.map((item) => {
                            return item.name;
                        });
                        if (payload != undefined) {
                            this.setOwnerDropdownAction(payload);
                        }
                    });
                },
            },
            show_search() {
                this.search_text = "";
                /*if(this.show_search){
                window.webix.$$('levelsTree').closeAll();
            }*/
                if (this.show_search == false) {
                    window.webix.$$("levelsTree").filter();
                }
            },
            search_text(newVal) {
                if (window.webix.$$("levelsTree") != undefined) {
                    if (this.search_text === "") {
                        window.webix.$$("levelsTree").filter();
                        window.webix.$$("levelsTree").closeAll();
                    } else {
                        window.webix.$$("levelsTree").filter("#value#", newVal);
                    }
                }
            },
            allow_tree_selection() {
                // hack to redraw the tree with checkboxes
                this.is_loading = true;
                this.$nextTick(() => {
                    this.is_loading = false;
                    this.$eventHub.$emit("treeCheckboxesEnabled");
                });
            },
        },
        components: {
            //MenuItem,
            UsersList,
            WebixTree,
            simplebar,
            VueTypeaheadBootstrap,
            GuidelineModalButton,
            TeamsMenu,
        },
        computed: {
            ...menuState,
            ...levelComputed,
            ...adminComputed,
            ...activityComputed,
            ...handbookComputed,
            ...profileComputed,
            ...peopleComputed,
            isChatVisible() {
                return this.$store.state.chat.is_chat_visible;
            },
            users(){
                return this.all_users.map( user =>{
                    return `${user.name} | ${user.email}`;
                });

            },
            is_another_component_loading(){
                return this.is_loading_activity_data || this.is_loading_document_data || this.is_loading_menu_users;
            },
            repositionTargetName() {
                let name = "";
                if (!this.reposition.target) {
                    return 'not set';
                } else {
                    //* find level name for the target from levels vuex
                    let level = _.find(this.levels, {
                        id: this.reposition.target,
                    });
                    if (level) {
                        name = level.name;
                    }
                    return name;
                }
            },
            repositionSourceName() {
                let name = "";
                if (!this.reposition.source) {
                    return 'not set';
                } else {
                    //* find level name for the source from levels vuex
                    let level = _.find(this.levels, {
                        id: this.reposition.source,
                    });
                    if (level) {
                        name = level.name;
                    }
                    return name;
                }
            },
            selection_lock() {
                return this.lock_level_selection;
            },

            isPeopleRoute() {
                //* Used to display the org chart option in the dropdown menu
                //* only display if we are in the people route
                let valid = false;
                if (this.$route.name == "people") {
                    valid = true;
                }
                return valid;
            },

            isLevelSelected() {
                //* Style the current selected level menu node
                var style = "";
                if (
                    this.returnLevels.length > 0 &&
                    this.selected_level != null
                ) {
                    if (this.returnLevels[0].id == this.selected_level.id) {
                        style = "; font-weight: bold;";
                    }
                }
                return style;
            },

            isMenuLoading() {
                /*if(this.menu_loading){
                return true;
            }
            else{
                return false;
            }*/
                return false;
            },

            returnLevels() {
                if (this.levels == null) {
                    return [];
                } else {
                    var grouped = [];
                    grouped = this.processLevels(this.levels);
                    return grouped;
                    //return this.levels;
                }
            },
            isParent() {
                var parent = false;
                if (this.selected_level != null) {
                    if (this.selected_level.parent_id == null) {
                        parent = true;
                    }
                }
                return parent;
            },
        },
        methods: {
            ...levelMethods,
            ...peopleMethods,
            ...menuMethods,
            ...activityMethods,
            ...profileMethods,
            //* typeahead
            selectedItemEvent(item){
                //* assumes there is a '|' character as a delimeter (included a blank space as well)
                let email = item.split('| ')[1];
                let user = this.all_users.find( user => {
                    return user.email == email;
                });
                
                if(user){
                    this.modified_lead_user = true;
                    this.new_level.lead_user_id = user.id;
                    //this.resetTypeahead();
                }
            },
            displayOrgChart() {
                let value = this.hiddenOrgChart
                this.hideOrgChart(!value)
            },
            resetTypeahead(){
                this.$nextTick(()=>{
                    this.query = '';
                    let input = this.$refs['userSearch'].$refs['input'];
                    input.blur();
                })
            },
            expandTree() {
                window.webix.$$("levelsTree").openAll();
            },
            collapseTree() {
                window.webix.$$("levelsTree").closeAll();
            },
            openTreeNode(tree_component, id){
                    let item = tree_component.getItem(id);
                    while(item.$parent != 0){
                        //console.log('WHILE LOG 1')
                        tree_component.open(item.$parent);
                        item = tree_component.getItem(item.$parent);
                    }
            },
            newTopLevel() {
                this.new_level.parent_id = 0;
                this.showAddModal = true;
                this.$nextTick(() => {
                    this.$refs.newLevelModal.show();
                    this.handleModalClose();
                    this.$nextTick(() => {
                        this.$refs.levelNameInput.focus();
                    });
                });
            },
            toggleDrag() {
                this.enable_drag_mode = !this.enable_drag_mode;
                this.$nextTick(() => {
                    //if(this.enable_drag_mode){
                    //  window.webix.$$('levelsTree').define({'drag': 'move'});
                    //}
                    //else{
                    //  window.webix.$$('levelsTree').define({'drag': false});
                    //}
                    this.$nextTick(() => {
                        window.webix.$$("levelsTree").refresh();
                    });
                });
            },
            gotoHomeLevel() {
                //* check if any visible tables have unsaved changes
                let has_unsaved_table_changes = this.$allTablesUnsavedChangesCheck();
                if(has_unsaved_table_changes){
                    return false;
                }
                //* continue with level selection if no tables have unsaved changes

                //* check if user has a home level
                if (
                    this.core.user_levels != null ||
                    this.core.user_levels != []
                ) {
                    let id = this.core.id;
                    let level_id = this.core.user_levels[0].id;
                    this.must_select_user = true;
                    this.clickedLevelEvent(level_id);
                    let user_payload = {
                        id: id,
                        job_key: "COO",
                    };
                    this.setSelectedUser(user_payload);
                    this.$eventHub.$emit("homeButtonEvent");
                } else {
                    alert("Your account doesn't belong to any level.");
                }
            },

            handleLevelAction(payload) {
                switch (payload.menu_action) {
                    case "New Top Level": {
                        this.modal_title = "New Company Level";
                        this.new_level.parent_id = 0;
                        this.new_level.name = "";
                        this.showAddModal = true;
                        this.$nextTick(() => {
                            this.$refs.newLevelModal.show();
                            this.handleModalClose();
                        });
                        break;
                    }
                    case "Add Child": {
                        this.modal_title = "New Child Level";
                        this.new_level.parent_id = payload.id;
                        this.new_level.name = "";
                        this.new_level.id = -1
                        this.showAddModal = true;
                        this.$nextTick(() => {
                            this.$refs.newLevelModal.show();
                            this.handleModalClose();
                        });
                        break;
                    }
                    /*case 'Add To Parent':{
              this.modal_title = "New Parent Level"
              let index = _.findIndex(this.levels, {id: payload.id})
              if(index != -1){
                  this.new_level.parent_id = this.levels[index].parent_id;
                  this.new_level.name = "";
              }
              this.showAddModal = true;
              this.$nextTick(() => {
                  this.$refs.newLevelModal.show();
              });
              break;
          }*/
                    case "Edit Current": {
                        let index = _.findIndex(this.levels, {
                            id: Number(payload.id),
                        });
                        if (index != -1) {
                            this.modal_title = "Edit Level";
                            this.new_level.parent_id = this.levels[index].parent_id;
                            this.new_level.id = this.levels[index].id;
                            this.new_level.name = this.levels[index].name;
                            this.new_level.lead_user_id = this.levels[index].lead_user.length == 0 ? -1 : this.levels[index].lead_user.id;
                            
                            let user = this.all_users.find( u => {
                                return u.id == this.new_level.lead_user_id;
                            });
                            if(user){
                                console.log('Found',  this.new_level.lead_user_id, user)
                                this.query = `${user.name} | ${user.email}`;
                            }
                            this.new_level.type = "level"
                            this.showAddModal = true;
                            this.$nextTick(() => {
                                this.$refs.newLevelModal.show();
                                this.handleModalClose();
                            });
                        } else {
                            alert("Error...");
                        }
                        break;
                    }
                    case "Delete": {
                        this.deleteLevel(payload.id);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },

            handleDragEvent(data) {
                this.is_loading = true;
                let level = _.find(this.levels, { id: Number(data.id) });
                let params = {
                    id: Number(data.id),
                    name: level.name,
                    parent_id: Number(data.parent_id),
                    type: "level",
                };
                window.axios
                    .post("api/admin/savelevel", params)
                    .then(() => {
                        this.loadNewLevels();
                    })
                    .catch(() => {
                        alert("Drag error");
                        this.is_loading = false;
                    });
            },

            //* New Tree Related Methods
            clicker(event, id) {
                //*
                console.log("Right Click", event, id);
            },
            clickedLevelEvent(id) {
                let index = _.findIndex(this.levels, { id: Number(id) });
                if (index != -1) {
                    let item = this.levels[index];
                    this.clickTreeItem(item);
                }
            },
            clickTreeItem(item, first_click = false) {
                let el = this;
                let selected_id = -1;
                if (this.selected_level != null) {
                    selected_id = this.selected_level.id;
                }
                if (item.id != selected_id) {
                    //* clear store
                    this.clearData();
                    this.selectLevel(item).then(() => {
                        if (first_click) {
                            //* set the current level as expanded
                            let node_ref = this.$refs["levelNode" + item.id];
                            if (node_ref != undefined) {
                                node_ref[0].classList.value += " mm-show";
                                if (item.parent_id != null) {
                                    //* Set parent level as expanded
                                    let parent_ref = this.$refs[
                                        "levelNode" + item.parent_id
                                    ];
                                    if (parent_ref != undefined) {
                                        parent_ref[0].classList.value +=
                                            " mm-show";
                                    }
                                }
                            }
                        }
                        el.$eventHub.$emit("newLevelSelected2");
                        el.$eventHub.$emit("level-changed");
                    });
                }
            },
            //* -----------------------
            checkIfLevelIsChild(new_level_id) {
                let user_level_ids = this.core.user_levels.map(
                    (user_lvl) => user_lvl.id
                );
                if (user_level_ids.includes(new_level_id)) {
                    //* new id is assigned level id so it is valid
                    return true;
                }

                let finished = false;
                let level = this.levels.find((lvl) => lvl.id == new_level_id);
                if (!level) {
                    return false;
                }

                while (finished == false) {
                    //console.log('WHILE LOG 2')
                    if (user_level_ids.includes(level.parent_id)) {
                        finished = true;
                        return true;
                    } else if (level.parent_id != null) {
                        level = this.levels.find(
                            (lvl) => lvl.id == level.parent_id
                        );
                    }

                    if (level.parent_id == null) {
                        finished = true;
                    }
                }

                return false;
            },

            showOrgChart() {
                //* Event used to toggle org chart (Recieved in the people component)
                this.$eventHub.$emit("showOrgChart");
            },
            processLevels(levels, skip = false) {
                var data = _.cloneDeep(levels);
                data.forEach((element) => {
                    if (this.selected_level != null && skip == true) {
                        if (this.selected_level.id == element.id) {
                            element["selected"] = true;
                        }
                    }
                    element["parent"] = element.parent_id;
                    element["text"] = element.name;
                });

                data.forEach((ele) => {
                    let parent_id = ele.parent_id;
                    if (parent_id == null) {
                        //* Parent item -- do nothing
                    } else {
                        //* If element is a child element, push to children array.
                        data.forEach((d) => {
                            if (d.id === parent_id) {
                                let childArray = d.nodes;
                                if (!childArray) {
                                    childArray = [];
                                }
                                childArray.push(ele);
                                d.nodes = childArray;
                            }
                        });
                    }
                });
                //* Remove duplicate elements
                data = data.filter((ele) => ele.parent_id == null);
                //*Set parent root as expanded;
                /*if(data[0] != undefined){
        data[0]["opened"] = true;
      }*/

                //* save processed levels to vuex store -- used for organogram */
                this.saveLevels(data);

                return data;
            },

            setSelectedLevel(index) {
                this.$nextTick(() => {
                    this.selectLevel(this.levels[index]);
                    //this.selectLevel(this.levels[index]).then(() => {
                    //    //* Tell visible components to update data based on level
                    //    //TODO - should use a better event name
                    //    window.tree_inst.$eventHub.$emit("newLevelSelected2");
                    //});
                });
            },
            clickLevel(node) {
                this.selected_level_id = node.id;
                this.$nextTick(() => {
                    var index = _.findIndex(this.levels, { id: node.id });
                    if (index != -1) {
                        this.setSelectedLevel(index);
                    }

                    var menu_index = _.findIndex(this.menu_data, {
                        id: node.id,
                    });
                    if (menu_index != -1) {
                        this.menu_data[menu_index].state = true;
                        //! REMOVED CLONE DEEP 31 mar 2023
                        //let temp = _.cloneDeep(this.menu_data);
                        this.updateMenu(this.menu_data);
                    }
                });
            },
            deleteLevel(id) {
                //let item = window.webix.$$("levelsTree").getItem(id);
                // if (item.$count > 0) {
                //     Swal.fire(
                //         "Can't delete a level with children.",
                //         "",
                //         "warning"
                //     );
                // } else {
                //     this.showDeletePrompt(id);
                // }
                this.showDeletePrompt(id);
            },
            showDeletePrompt(id) {
                // if (this.selected_level.id == Number(id)) {
                //     Swal.fire(
                //         "Unable to delete level because it is selected in the Organisational Structure menu"
                //     );
                // } else {
                    let level = _.find(this.levels, {
                        id: Number(id),
                    });
                    Swal.fire({
                        title: `Delete "${level.name}" ?`,
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                        if (result.value) {
                            //* If user selects yes
                            this.deleteSelectedItem(id);
                        }
                    });
                //}
            },
            deleteSelectedItem(id, force_delete = false, move_child_levels = false) {
                var params = {
                    level_id: Number(id),
                    force_delete: force_delete,
                    move_child_levels: move_child_levels,
                };
                window.axios
                    .post("api/admin/deletelevel", params)
                    .then((response) => {
                        //* No linked items were found
                        if(response.data.success){
                            //* refresh level data
                            Swal.fire({
                                icon: "success",
                                title: "Deleted",
                                toast: true,
                                position: "top",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            });
                            this.is_loading = true;
                            this.clickedLevelEvent(this.company_id);
                            this.loadLevels().then(() => {
                                this.is_loading = false;
                                this.show_tree = false;
                                this.$nextTick(() => {
                                    this.show_tree = true;
                                });
                            });
                        }
                        else{
                            let list_of_linked_items = this.getLinkedItemList(response.data.linked_items);
                            let table_html = "";
                            const table_top = `
                            <div class="table-responsive" style="font-size: smaller;">
                                <table class="table table-hover table-bordered table-sm ">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left;" scope="col">Name</th>
                                            <th scope="col">Count</th>
                                        </tr>
                                    </thead>
                                    <tbody style="overflow-y: auto; max-height: 250px;">
                            `;
                            const table_bottom = `
                                    </tbody>
                                </table>
                            </div>
                            `;

                            //insert top of table
                            table_html = table_top;
                            //insert table rows
                            list_of_linked_items.forEach( row => {
                                table_html += row;
                            });
                            //insert bottom part of table
                            table_html += table_bottom;

                            //* insert move children checkbox
                            let checkbox_html = `
                                <div style="font-size: smaller;" class="alert alert-danger">
                                    <input type="checkbox" id="deleteCheckbox" class="me-2" />
                                    <span style="cursor: pointer;" id="checkboxLabel">
                                        Move users to company level
                                    </span>
                                <div>
                            `;
                            table_html += checkbox_html;

                            Swal.fire({
                                title: 'Linked Items Found' ,
                                text: "Force Delete this level?",
                                icon: "warning",
                                html: table_html,
                                showCancelButton: true,
                                confirmButtonColor: "#34c38f",
                                cancelButtonColor: "#f46a6a",
                                confirmButtonText: "Yes, delete it!",
                                didOpen: () => {
                                    // Get the checkbox element
                                    const checkbox = document.getElementById('deleteCheckbox');

                                    // Get the span element
                                    const span = document.getElementById('checkboxLabel');

                                    // Add the click event listener to the span
                                    span.addEventListener('click', () => {
                                    // Toggle the checkbox state when the span is clicked
                                    checkbox.checked = !checkbox.checked;
                                    });
                                }
                            }).then((result) => {
                                if (result.value) {
                                    //* get checkbox state
                                    const move_children_checkbox = document.getElementById('deleteCheckbox').checked;
                                    //* If user selects yes
                                    this.deleteSelectedItem(id, true, move_children_checkbox);
                                }
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                        //! old it
                        //if (force_delete == false) {
                        //    Swal.fire({
                        //        title: 'Linked Items Found' ,
                        //        text: "Force Delete this level?",
                        //        icon: "warning",
                        //        showCancelButton: true,
                        //        confirmButtonColor: "#34c38f",
                        //        cancelButtonColor: "#f46a6a",
                        //        confirmButtonText: "Yes, delete it!",
                        //    }).then((result) => {
                        //        if (result.value) {
                        //            //* If user selects yes
                        //            this.deleteSelectedItem(id, true);
                        //        }
                        //    });
                        //}
                        this.is_loading = false;
                    });
            },
            createLevel(type) {
                //* Show the add level modal - can be added as a child or a normal parent level
                if (type == "child") {
                    this.new_level.parent_id = this.selected_level.id;
                } else {
                    this.new_level.parent_id = this.selected_level.parent_id;
                }
                this.showAddModal = true;
                this.$nextTick(() => {
                    this.$refs.newLevelModal.show();
                    this.handleModalClose();
                });
            },

            getLinkedItemList(linked_items_object){
                let table_rows = [];
                console.log('DEBUG 1', linked_items_object);
                //* key of object is the item type name, and the value is the item count (eg objectives: 1 or levels:0 or users: 10 ...)
                for (var key in linked_items_object){
                    if(linked_items_object[key] > 0){
                        let row_html = `
                        <tr>
                            <td class="text-capitalize" style="text-align: left;">${key}</td>
                            <td>${linked_items_object[key]}</td>
                        </tr>
                        `
                        table_rows.push(row_html);
                    }
                }
                return table_rows;
            },

            handleSubmit(e) {
                e.preventDefault();
                if (this.new_level.name === "") {
                    Swal.fire({
                        icon: "error",
                        title: "Enter Level Name!",
                        toast: true,
                        position: "top",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    });
                } else {
                    this.saveLevel();
                }
            },
            handleModalClose(){
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "newLevelModal") {
                        this.showAddModal = false;
                        this.query = '';
                        this.new_level = {
                            id: -1,
                            name: "",
                            parent_id: "",
                            type: "level",
                            lead_user_id: -1,
                        };
                    }
                });
            },

            saveLevel() {
                this.is_loading = true;
                if(this.new_level.parent_id == null || this.new_level.parent_id == 0){
                    this.new_level.parent_id = '';
                }
                else{
                    const tree_level = window.webix.$$('levelsTree').getItem(this.new_level.parent_id);
                    this.new_level['item_order'] = Number(tree_level.$count) + 1;
                }
                
                axios
                    .post(this.base + "api/admin/savelevel", this.new_level)
                    .then(( response ) => {
                        //eslint-disable-next-line
                        let new_level_id = response.data.level_id
                        if(this.modified_lead_user || this.query == ''){
                            window.axios.post('api/people/make_level_lead', {
                                user_id: this.query != '' ? Number(this.new_level.lead_user_id) : -1,
                                level_id: Number(new_level_id),
                            })
                            .then(()=>{
                                this.modified_lead_user = false;
                                this.showAddModal = false;
                                this.loadLevels().then(() => {
                                    this.is_loading = false;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Saved!",
                                        toast: true,
                                        position: "top",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                    });
                                });
                            })
                        }
                        else{
                            this.showAddModal = false;
                            this.loadLevels().then(() => {
                                this.is_loading = false;
                                Swal.fire({
                                    icon: "success",
                                    title: "Saved!",
                                    toast: true,
                                    position: "top",
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                });
                            });
                        }
                    })
                    .catch(() => {
                        alert("Error Saving Level");
                        this.new_level = {
                            id: -1,
                            name: "",
                            parent_id: "",
                            type: "level",
                        };
                    });
            },
            processMenuData(data) {
                let index = _.findIndex(this.menu, { id: data.id });

                if (index != -1) {
                    this.menu_data[index] = data;
                } else {
                    this.menu_data.push(data);
                }
            },
            restoreHierarchyTree() {
                if (this.current_level_id != null) {
                    let id_array = [];
                    let item = _.find(this.levels, {
                        id: this.current_level_id,
                    });
                    id_array.push(this.current_level_id);
                    if (item != undefined) {
                        while (item.parent_id != null) {
                            //console.log('WHILE LOG 3')
                            id_array.push(item.parent_id);
                            //if(item.parent_id != null){
                            item = _.find(this.levels, { id: item.parent_id });
                            //}
                        }
                        id_array.reverse();
                        this.$nextTick(() => {
                            id_array.forEach((id) => {
                                window.webix.$$("levelsTree").open(id);
                            });
                            //window.webix.$$('levelsTree').addCss(this.current_level_id, 'selected_tree_item')
                        });
                    }
                }
            },
            loadNewLevels() {
                this.is_loading = true;
                this.loadLevels().then(() => {
                    this.is_loading = false;
                    this.$nextTick(() => {
                        this.restoreHierarchyTree();
                    });
                });
            },
            updateSelectedLevelAndUser(payload) {
                this.must_select_user = true;
                this.new_selection_id = payload.user_id;
                this.clickedLevelEvent(payload.level_id);
            },
        },
        mounted() {
            if (this.menu != []) {
                this.menu_data = _.cloneDeep(this.menu);
            }

            //window.tree_inst = this;

            this.$eventHub.$on("clickedLevelItem", this.clickLevel);
            this.restoreHierarchyTree();

            this.$eventHub.$on("loadNewLevels", this.loadNewLevels);
            this.$eventHub.$on(
                "forceSelectNewLevel",
                this.updateSelectedLevelAndUser
            );
        },
        beforeDestroy() {
            //window.tree_inst = null;
            this.$eventHub.$off("clickedLevelItem");
            this.$eventHub.$off("loadNewLevels");
            this.$eventHub.$off("forceSelectNewLevel");
        },
    };
</script>

<style scoped>
    .tree-default .tree-themeicon {
        width: 0px !important;
        margin: 0px !important;
    }

    .tree-anchor > span {
        font-size: 0.82rem;
    }
    /*div > ul > li > ul{
    margin-left: -1rem !important;
}
div > ul > li > ul{
    margin-left: -1rem !important;
}*/

    /* metis folder style classes */
    .metisFolder {
        padding-left: 0;
    }
    .metisFolder,
    .metisFolder ul {
        list-style: none;
    }
    .metisFolder li {
        position: relative;
    }
    .metisFolder li a {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #333;
        text-decoration: none;
        outline: 0 none;
    }
    .metisFolder ul:before {
        position: absolute;
        border-left: 1px dotted #333;
        content: "";
        width: 30px;
        top: 25px;
        left: 5px;
        bottom: 17px;
    }
    .metisFolder ul > li:before {
        position: absolute;
        border-top: 1px dotted #333;
        content: "";
        width: 30px;
        top: 13px;
        left: -26px;
    }
    li.active > a > span.fa-folder-o:before {
        content: "\f115" !important;
    }

    div .tree-default > ul > li {
        margin-left: -1.5rem !important;
    }
</style>
