<template>
    <b-modal class="guideline-modal" :scrollable="true" size="xl" title='Guideline' id='globalGuideline'>
        <b-overlay :show="is_loading_guidelines">

            <ejs-documenteditorcontainer v-if="is_editing" height="1000px" ref="container" :serviceUrl='serviceUrl' :showPropertiesPane='false'
                :enableToolbar='true' :toolbarItems="toolBarItems"></ejs-documenteditorcontainer>

            
            <div style="resize: vertical;" class="">
            <ejs-documenteditor v-if="!is_editing && hasGuidelineData"  ref="documenteditor" :serviceUrl='serviceUrl' :created="onCreated" :isReadOnly='true' :showPropertiesPane='false'> </ejs-documenteditor>

            </div>

            <b-alert :show="is_editing == false && hasGuidelineData == false" variant="primary">
                No guideline text available.
            </b-alert>
        </b-overlay>


        
        <template #modal-footer>
            <!-- footer -->
            <div class='d-flex' v-if="is_editing == false">
                <b-button v-if="isAndrewUser" @click='edit' class='ms-auto mt-2 ms-2' variant='primary'>Edit</b-button>
            </div>

            <div class="d-flex" v-if="is_editing">
                <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
            </div>
        </template>
    </b-modal>

</template>
<style>
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import "../../../node_modules/@syncfusion/ej2-documenteditor/styles/bootstrap5.css";
</style>
<script>
/* eslint-disable */
import { guidelineMethods, guidelineComputed, adminComputed } from '@/state/helpers';
//import CKEditor from 'ckeditor4-vue';
import { DocumentEditorContainerComponent, DocumentEditorComponent, Toolbar } from '@syncfusion/ej2-vue-documenteditor';
export default {
    data: () => ({
        is_editing: false,
        editor_data: [],
        text: '',
        serviceUrl: "https://docservice.diversitude.co.za/api/documenteditor/",
        hasGuidelineData: false,
        toolBarItems: ['New', 'Open', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'LocalClipboard', 'Separator', 'FormFields', 'UpdateFields']
    }),
    provide: {
        //Inject require modules.
        DocumentEditorContainer: [Toolbar],
        DocumentEditor: []
    },
    computed: {
        /*hasGuidelineData() {
            return this.getSelectedGuideline !== undefined;
        },*/
        /*guidline_body() {
            if (this.hasGuidelineData) {
                return this.getSelectedGuideline.guideline
            }
            return '';
        },*/
        ...adminComputed,
        ...guidelineComputed,
        isAndrewUser() {
            return this.core.id == 38;
        }
    },
    methods: {
        ...guidelineMethods,
        save() {
            this.$refs.container.ej2Instances.documentEditor.saveAsBlob('Sfdt').then((blob) => {
                let exportedDocument = blob;
                let formData = new FormData();
                formData.append('documentName', this.$refs.container.ej2Instances.documentEditor.documentName);
                formData.append('type', this.selected_guideline_id);
                formData.append('file', exportedDocument);
                axios.post('api/admin/save_guideline', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.status === 200 || response.status === 304) {
                        console.log('Saved successfully');
                        this.$swal.fire({
                                icon: "success",
                                title: "Saved Guideline Content",
                                toast: true,
                                position: "top",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            });
                        this.$bvModal.hide('globalGuideline');
                    }
                }).catch(error => {
                    console.error('Error saving document:', error);
                    this.$swal.fire({
                                title: "Error saving guideline text",
                                toast: true,
                                position: "top",
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            });
                });
            });
            /*this.saveGuidelineBody({
                type: this.selected_guideline_id,
                body: this.editor_data
            })
            .then(()=>{
                this.is_editing = false;
            })*/
        },
        edit() {
            this.is_editing = !this.is_editing;
            if (this.editor_data.length > 0) {
                setTimeout(() => {
                    if (this.is_editing) {
                        this.$refs.container.ej2Instances.documentEditor.open(this.editor_data);
                        
                    } else {
                        this.$refs.documenteditor.open(this.editor_data);
                        
                    }
                }, 150);
            }
            setTimeout(() => {
                if(this.$refs.container) {
                    this.$refs.container.ej2Instances.documentEditor.selection.characterFormat.fontFamily= 'Arial';
                }
            }, 200)
            
        },
        loadGuideline() {
            axios.get('api/admin/guideline', { params : {type: this.selected_guideline_id}})
                .then((response)=>{
                    if (response.data.length > 0) {
                        this.hasGuidelineData = true;
                        this.editor_data = response.data;
                        setTimeout(() => {
                            //this.$refs.container.ej2Instances.documentEditor.open(response.data);
                            this.$refs.documenteditor.open(this.editor_data);
                        }, 150);
                    } else {
                        this.hasGuidelineData - false;
                    }
                });
        },
        onWindowResize: function () {
            //Resizes the document editor component to fit full browser window automatically whenever the browser resized.
            this.updateDocumentEditorSize();
        },
        updateDocumentEditorSize: function () {
            //Resizes the document editor component to fit full browser window.
            //var windowWidth = window.innerWidth;
            //var windowHeight = window.innerHeight;
            console.log(this.$refs);
            //this.$refs.documenteditor.resize(windowWidth, windowHeight);
        },
        onCreated: function () {
            setTimeout(()=>{
                this.updateDocumentEditorSize();
            }, 100)
            // this.nextTick(() => {
            
            // }, 100);
            //Adds event listener for browser window resize event.
            window.addEventListener('resize', this.onWindowResize.bind(this));
        },

    },
    mounted() {
        this.loadGuideline();
    },
    beforeDestroy(){
        window.removeEventListener('resize', this.onWindowResize.bind(this));
    }
}
</script>
<style scoped>
.modal-xl {
  max-height: 90vh; /* Set maximum height to 100% of viewport height */
}

.e-tab-text {
    font-size: 0.78rem !important
}
.e-de-prop-dropdown {
    font-size: 0.75rem !important
}
.e-de-status-bar button {
    font-size: 0.75rem !important
}
.e-de-pagenumber-input {
    font-size: 0.75rem !important
 }
.e-de-ctnr-pg-no-spellout {
    font-size: 0.75rem !important
}
.e-input .e-de-pagenumber-text {
    font-size: 0.75rem !important
}
.e-tbar-btn-text {
    font-size: 0.75rem !important
}

.modal-dialog {
  display: flex; /* Enable flexbox layout */
  flex-direction: column; /* Stack content vertically */
  height: 90%; /* Ensure the dialog takes up full height */
}

.modal-content {

  overflow-y: auto; /* Add scroll if content exceeds height */
}
</style>