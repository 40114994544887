<script>
import router from "@/router";
import { layoutComputed, layoutMethods, ganttComputed, ganttMethods, uxToggleComputed, settingsComputed } from "@/state/helpers";
import ChatSidenavContainer from "../../components/menu/chat/chatSidenavContainer.vue";
//import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
// import TopNavNew from "@/components/top-nav-new"
import RightBar from "@/components/right-bar";
//import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  components: { SideBar, RightBar, ChatSidenavContainer },
  data() {
    return {
      show_view: true,
      type: this.$store ? this.$store.state.layout.leftSidebarType : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      loading: false,
      isMenuCondensed: false,
    };
  },
  computed: {
    ...uxToggleComputed,
    ...layoutComputed,
    ...ganttComputed,
    ...settingsComputed
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    document.body.classList.remove("auth-body-bg");
  },
  watch: {
    layoutWidth() {
      this.loading = true
      this.$nextTick(() => {
        this.loading = false
      })
    }
  },
  methods: {
    ...layoutMethods,
    ...ganttMethods,
    startDebug(count = 50){
      this.$swal.fire({
                            icon: "info",
                            title: count,
                            toast: true,
                            position: "top",
                            showConfirmButton: false,
                            timer: 100,
                            timerProgressBar: true,
                        });
                        
      this.setMemLeakBool(false)//this.show_view = false;
      setTimeout(()=>{
        this.setMemLeakBool(true)
        setTimeout(()=>{
          if(count == 0){
            return;
          }
          this.startDebug(count-1);
        }, 400)
      }, 150)

    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");
      // if (window.screen.width >= 1366) {
      //   // eslint-disable-next-line no-unused-vars
        
      // } else {
      //   // eslint-disable-next-line no-unused-vars
      //   router.afterEach((routeTo, routeFrom) => {
      //     document.body.classList.remove("sidebar-enable");
      //   });
      //   document.body.classList.remove("vertical-collpsed");
      // }
      // eslint-disable-next-line no-unused-vars
      router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    // window.addEventListener("resize", this.toggleMenu);
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    
    <!-- removed class from layout-wrapper 'bg-white ' -->
    <div id="layout-wrapper" class="shadow-lg rounded" :style="layoutWidth == 'fluid' ? `` : `max-width: ${layoutWidth}; width: ${layoutWidth}`">
      <!-- <NavBar /> -->
      <!-- <TopNavNew /> -->
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content d-flex" id="mainContent" :style="layoutWidth == 'fluid' ? `justify-content: center;` : `justify-content: center; max-width: ${layoutWidth};`">
        
        <div v-bind:class="((this.$route.name.includes('handbook')) || (this.fullscreen_kanban == true))?'page-content handbook kanban w-100':'page-content w-100'" v-if="!loading" >
          <b-button v-if="false" @click="startDebug()">Debug Reload</b-button>
          <!-- Start Content-->
          <div v-if="show_view" class="container-fluid">
            <slot />
            <ChatSidenavContainer/>
          </div>
            <!-- <Footer class="d-flex w-100" style="justify-content: end;" />         -->
        </div>   
      </div>
      <RightBar />
    </div>
  </div>
</template>

<style>
#layout-wrapper {
  justify-content: center; 
  min-height: 100vh; 
  background-color: #EDEDED !important;
  /* border: 1px solid black; */
  /* box-shadow: 7px 0 7px -4px #3A0D4A, -7px 0 4px -4px #3A0D4A; */
}
</style>
