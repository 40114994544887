<script>
//import MetisMenu from "metismenujs/dist/metismenujs";
import LevelMenu from "./menu/level-menu.vue";
import appConfig from "@/app.config";
//import NavigationMenu from './menu/navigation-menu.vue';
import _ from "lodash";
import {
  settingsComputed,
  settingsMethods,
  adminMethods,
  adminComputed,
  levelMethods,
  levelComputed,
  menuMethods,
  menuState,
  authMethods,
  layoutComputed,
  layoutMethods
} from "@/state/helpers";
import store from '@/state/store'
import tableHelpers from '@/mixins/tableHelpers.js';
//import router from "@/router";
import SearchModal from "@/components/widgets/searchModal";
import dateFilter from "@/components/menu/dateFilter";
import pinnedYear from "@/components/menu/pinnedYear";

import moduleSelector from "@/components/menu/moduleSelector";
import pinnedYearType from "@/components/menu/pinnedYearType";
// import notificationMenu from "./menu/notificationMenu.vue";
import { menuItems } from "./menu";
import userMenu from "@/components/menu/userMenu.vue";

/**
 * Sidenav component
 */
export default {
  mixins: [tableHelpers],
  data: () => ({
    menuItems: menuItems,
    menuData: null,
    show_menu: false
    //is_loading: false,
  }),
  components: {
    //NavigationMenu,
    LevelMenu,
    SearchModal,
    dateFilter,
    moduleSelector,
    pinnedYear,
    pinnedYearType,
    userMenu,
    // notificationMenu
  },
  mounted: function() {
    if (this.levels != null) {
      this.show_menu = true;
    }
    let el = this
    this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
        console.log(el.is_left_menu_collapsed)
        if(window.innerWidth < 1366 && !el.is_left_menu_collapsed){
          el.toggleMenu()
        }
    }, 500));
  },
  computed: {
    ...levelComputed,
    ...adminComputed,
    ...settingsComputed,
    ...menuState,
    ...layoutComputed,
    getAppVersion(){
      return appConfig.version;
    },
    is_collapsed() {
      return this.is_left_menu_collapsed;
    },
    is_loading() {
      if (this.menu_loading === "true") {
        return true;
      }
      if (this.menu_loading === "false") {
        return false;
      }

      return this.menu_loading;
    },
    printRouteName() {
      switch (this.$route.name) {
        case "objectives": {
          return "Performance";
        }
        case "people": {
          return "People";
        }
        case "process": {
          return "Process";
        }
        case "model": {
          return "Org Profile";
        }
        case "profile": {
          return "Org Profile";
        }
        default: {
          return "";
        }
      }
    }
  },
  methods: {
    ...menuMethods,
    ...adminMethods,
    ...levelMethods,
    ...settingsMethods,
    ...authMethods,
    ...layoutMethods,
    setLoading: menuMethods.setLoading,
    setWidescreen(value) {
        if(value) {
            this.changeLayoutWidth({
                layoutWidth: '1920px',
            });
        } else {
            this.changeLayoutWidth({
                layoutWidth: 'fluid',
            });
        }
        setTimeout(() => {
            this.$eventHub.$emit('windowResizedEvent')
        }, 500)
    },
    handleLogout(){
        const has_unsaved_changes = this.$allTablesUnsavedChangesCheck();
        if(has_unsaved_changes){
            //* stop here if unsaved changes are found
            return;
        }
        this.validate()
        .then((valid)=>{
            if(valid){
                this.executeLogoutChain();
            }
            else{
                this.resetVuex();
                this.$router.push({name: 'login'});
            }
        })
        .catch(()=>{
            this.resetVuex();
            this.$router.push({name: 'login'});
        })
    },
    resetVuex(){
        store.dispatch('admin/clearStore');
        store.dispatch('levels/clearAll');
        store.dispatch('menu/clearAll');
        store.dispatch('people/clearAll');
        store.dispatch('activity/clearAll');
        store.dispatch('performance/objectives/clearAll')
        window.localStorage.removeItem('level_id');
    },
    executeLogoutChain(){
        window.axios.get('api/auth/logout')
        .then(()=>{
            window.localStorage.removeItem('x_token');
            window.localStorage.removeItem('auth.currentUser');
            this.logOut().then(()=>{
                this.resetVuex();
                this.$router.push({name: 'login'});
            })
        })
        .catch(()=>{
            this.resetVuex();
        })
    },
    toggleMenu() {
      this.setLeftMenuState();
        this.$nextTick(()=>{
            //* for webix tables
            ///this.$eventHub.$emit('windowResizedEvent')
            
            this.$parent.$parent.$parent.toggleMenu();
            this.$eventHub.$emit('leftMenuToggled')
        })
    },
    initLevelSelection() {
      setTimeout(() => {
        if (this.levels == null) {
          this.loadLevels().then(() => {
            this.setCurrentLevel();
          });
        } else {
          this.setCurrentLevel();
        }
      }, 200);
    },
    setCurrentLevel() {
      if (this.core_loaded == false) {
        return;
      }
      else if (this.selected_level == null) {
        let index = -1;
        if (this.core.admin) {
          index = _.findIndex(this.levels, { parent_id: 0 });
        } else if (
          this.core.user_levels != null ||
          this.core.user_levels != []
        ) {
          index = _.findIndex(this.levels, { id: this.core.user_levels[0].id });
        }

        if (index != -1) {
          this.selectLevel(this.levels[index]).then(() => {
            this.$eventHub.$emit("newLevelSelected");
          });
        }
        this.$nextTick(() => {
          this.show_menu = true;
        });
      } 
      else {
        this.$nextTick(() => {
          this.show_menu = true;
        });
      }
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    // toggleMenu(event) {
    //   //this.is_collapsed = !this.is_collapsed;
    //   event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    // },
    isActiveRoute(name) {
      //* get the base route param fron the route path
      let route_param = this.$route.path.split("/");
      if (route_param[1] == undefined) {
        return "light";
      } else if (route_param[1] == name) {
        return "success";
      } else {
        return "light";
      }
      /*if(this.$route.name == name){
                return 'success'
            }
            else if(this.$route.name == 'person' && name == 'people'){
                return 'success'
            }
            else{
                return 'light'
            }*/
    },
    setViewType(type) {
      console.log(type)
      let current_view = window.sessionStorage.getItem("current_view");
      if (current_view === type) {
        setTimeout(() => {
          this.setLoading(false);
        }, 600);
      }
      if (current_view !== type) {
        this.setLoading(true);
        window.sessionStorage.setItem("current_view", type);
      }
    },
    //setLoading(){
    //    this.is_loading = true;
    //},
    //clearLoading(){
    //    this.is_loading = false;
    //    //clear b-overlay
    //},
    handleMenuToggle() {
      
      this.is_collapsed = !this.is_collapsed;
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="menu_purple" :style="is_collapsed ? '' : 'padding-top: 0.1rem;'">
    <b-overlay :show="is_loading" opacity="0.3">
      <div id="" class="" keep-alive >
        <!-- Search Bar -->
        <!--<b-row v-if="is_collapsed == false">
            <b-col cols="8">
                <b-form-input placeholder="Search..." style="margin-left: 1rem;" type="text"></b-form-input>
            </b-col>
            <b-col cols="4">
                <b-button @click="$bvModal.show('searchModal')" style="float:right; margin-right: 1rem;">
                    <font-awesome-icon icon="fa-solid fa-search" />
                </b-button>
            </b-col>
        </b-row>-->
        <b-row v-if="is_collapsed == false">
          <b-col v-if="false" cols="12" class="pb-1">
            <div style="text-align: center;">{{ printRouteName }}</div>
          </b-col>
          <b-col cols="12" class="d-flex w-100" style="flex-direction: column; align-items: center;">
            <div class="d-flex w-100 ms-2" style="justify-content: space-between;">
              <!-- Back Arrow -->
              <span class="ms-3 mb-0 mt-1">
                <font-awesome-icon @click="toggleMenu" class="ms-1 mt-2" :icon="['fas', 'angle-left']" style="height: 16px !important; width: 16px !important; color: #736BA5" />
                
                <!-- <font-awesome-icon @click="toggleMenu" :icon="['fas', 'arrow-left']" style="color: #CBC3FF; " /> -->
              </span>
              
              <span class="me-1 mb-0">
                <!-- <notificationMenu /> -->
                <!-- <i @click="toggleMenu" class="arrow left ms-1 mt-3" style="height: 20px; width: 20px"></i> -->
                <!-- <font-awesome-icon @click="toggleMenu" :icon="['fas', 'arrow-left']" style="color: #CBC3FF; " /> -->
                <b-dropdown
                v-if="core != null"
                right
                id="userActionDropdown"
                variant="black"
                toggle-class=""
                menu-class="dropdown-menu-end"
                style="max-height: 50px !important;"
                class="p-0 m-0 ps-0"
                no-caret
                >
                <template v-slot:button-content>
                    <!-- img from b64 -->
                    <font-awesome-icon class="ps-0 m-0" :icon="['fas', 'ellipsis-h']" style="height: 15px; width: 15px; color: white" />
                    <!-- <span class="d-none d-xl-inline-block ms-1">{{ returnName }}</span> -->
                    <!-- <font-awesome-icon class="fa-fw" icon="fa-caret-down"/> -->
                </template>
                <!-- item-->
                <b-dropdown-item @click="setSelectedUser({id: core.id})">
                    <router-link tag="span" :to="`/people`">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-user"/>
                    {{ $t("navbar.dropdown.henry.list.profile") }}
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <a @click="handleLogout" class="dropdown-item text-danger cursor-pointer">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1 text-danger" icon="fa-power-off"/>
                    {{ $t("navbar.dropdown.henry.list.logout") }}
                </a>
               
                <!-- <b-dropdown-item v-if="isDevEnv()"  @click="$router.push({name: 'testzone'});">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-flask" />
                    TestZone
                </b-dropdown-item> -->
               
                <!-- <b-dropdown-item v-if="layoutWidth == '1920px'" @click="setWidescreen(false);">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-flask" />
                    Enable Full Screen
                </b-dropdown-item> -->
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item>
                    <!-- <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-flask" /> -->
                    Version {{ getAppVersion }}
                </b-dropdown-item>
               /
                <!-- <b-dropdown-item v-if="layoutWidth == 'fluid'" @click="setWidescreen(true);">
                    <font-awesome-icon class="fa-fw font-size-16 align-middle me-1" icon="fa-flask" />
                    Enable Box View
                </b-dropdown-item> -->
                </b-dropdown>
                
              </span>
              <!-- New Logo -->         
            </div>
            <div class="d-flex w-100" style="justify-content: center; margin-top: -10px;">
              <!-- New Logo -->
              <div class="navbar-brand-box" >
                  <router-link tag="a" to="/" class="">
                      <span class="logo-sm">
                          <img src="@/assets/images/logo-new.png" alt height="60" />
                      </span>
                      <!-- <span class="logo-lg">
                          <img src="@/assets/images/logo-new.png" alt height="17" />
                      </span> -->
                  </router-link>
              </div>
            </div>
            <div class="w-100">
              <userMenu />
            </div>
            <div class="w-100">
              <moduleSelector @viewChange="setViewType" />
            </div>
            <div class="w-100 mt-2">
              <pinnedYearType />
              <pinnedYear />
              <dateFilter />
            </div>
            <div
              class="btn-group"
              style="display:flex; flex-basis: 100% ;justify-content: center; flex-direction: column; align-items: center;"
            >

            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <div class="h-100">
            <div class="d-flex w-100" style="flex-direction: column; align-items: center; justify-content: center;">
              <!-- Back Arrow -->
              <span class="mb-0">
                <!-- <font-awesome-icon @click="toggleMenu" :icon="['fas', 'arrow-right']" style="color: #CBC3FF; height: 20px;" /> -->
                <font-awesome-icon @click="toggleMenu" class="ms-1 mt-2" :icon="['fas', 'angle-right']" style="height: 16px !important; width: 16px !important; color: #736BA5" />
              </span>
              <!-- New Logo -->
              <div class="" >
                  <router-link tag="a" to="/" class="">
                      <span class="logo-sm">
                          <img src="@/assets/images/logo-new-small.png" alt height="40" />
                      </span>
                      <!-- <span class="logo-lg">
                          <img src="@/assets/images/logo-new.png" alt height="17" />
                      </span> -->
                  </router-link>
              </div>
              <div class="w-100 d-flex">
                <moduleSelector @viewChange="setViewType" :compactView="true" style="margin-left: -2px !important;"/>
              </div>
              <div class="w-100 d-flex" style="justify-content: center; margin-left: 4px;">
                <!-- <notificationMenu :compactView="true" /> -->
              </div>
            </div>
          </div>
        </b-row>

        <div v-if="is_collapsed == false">
            <LevelMenu v-if="show_menu" />
            <!-- old nav menu 
              <NavigationMenu v-if="true != true" />-->
            <SearchModal />        
        </div>
      </div>
      <!-- Sidebar -->
    </b-overlay>
  </div>
</template>
<style>
.my-popover-class {
  background-color: #74788d !important;
}
.my-popover-class .popover-body {
  padding: 3px;
  color: white !important;
}
.simplebar-content-wrapper {
  border-radius: 0.4em !important;
}
#userActionDropdown__BV_toggle_ {
  margin-top: 2px !important;
}
.toggle-arrow {
  border: 10px solid #8881B3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
</style>
