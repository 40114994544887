<template>
    <div :class="compactView ? 'p-3 pb-0 pt-0 w-100' : 'p-3 pb-0 w-100'" role="group" v-if="!loading">
        <div v-if="!compactView" class="w-100" style="display: flex; flex-direction: column;">
            <span class="m-1">
                <!-- <span class="w-100">
                    <b-form-select class="module_select" v-model="selected_module" :options="modules"></b-form-select>
                </span> -->
                <b-dropdown toggle-class='customDropdown' no-caret menu-class="customMenuClass" class="moduleSelect" :text="getModuleName">
                    <b-dropdown-item class="customItem" style="color: white !important"
                        @click="select_module(option)"
                        v-for="option in modules"
                        :key="option.value"
                    >
                    <div class="d-flex">
                        <font-awesome-icon :icon="getIcon(option.value)" class="m-1 me-2" />
                        <span>
                            {{option.text}}
                        </span>
                    </div>
                    </b-dropdown-item>
                    <template #button-content>
                        <div class="" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                            <div class="icon-styles">
                                <font-awesome-icon :icon="getIcon(selected_module)" />
                            </div>
                            <div class="">
                                {{ getModuleName }}
                            </div>
                            <div class="" style="width: 35px; margin-top: 3px !important;">
                                <!-- <i class="arrow-dropdown down"></i> -->
                                <font-awesome-icon :icon="['fas', 'angle-down']" style="height: 16px !important; width: 16px !important; color: #50A5F1" />
                            </div>
                        </div> 
                    </template>
                </b-dropdown>
            </span>
        </div>
        <div v-if="compactView">
            <div class="w-100" style="display: flex; flex-direction: column;">
            <span class="m-1">
                <!-- <span class="w-100">
                    <b-form-select class="module_select" v-model="selected_module" :options="modules"></b-form-select>
                </span> -->
                <b-dropdown id="compactDropdown" toggle-class='compactCustomDropdown' no-caret menu-class="customMenuClass" class="compactModuleSelect" :text="getModuleName">              
                    <b-dropdown-item class="customItem"  style="color: white !important"
                        @click="select_module(option)"
                        v-for="option in modules"
                        :key="option.value"
                    >
                    <div class="d-flex">
                        <font-awesome-icon :icon="getIcon(option.value)" class="m-1 me-2" />  
                        <span>
                            {{option.text}}
                        </span>     
                    </div>
                    </b-dropdown-item>
                    <template #button-content>
                        <div class="" style="display:inline-flex;justify-content: space-between; width: 98%; align-items: center;">
                            <div class="icon-styles">
                                <font-awesome-icon :icon="getIcon(selected_module)" />
                            </div>
                        </div>
                    </template>
                </b-dropdown>
            </span>
        </div>
        </div>
    </div>
</template>

<script>
//import { menuMethods, authMethods } from '@/state/helpers'
export default {
name: "moduleSelector",
components:{
   
},
props: {
    compactView: {
        default: false
    }
},
mounted(){
    //this.setLoading(false);
    let route_param = this.$route.path.split("/");
    if (route_param[1] == undefined) {
        this.selected_module = "model";
    } else {
         this.selected_module = route_param[1];
    }
},
data: () => ({
    selected_module: 'model',
    loading: false,
    modules: [{ value: 'model', text: 'Model' },{ value: 'people', text: 'People' },{ value: 'performance', text: 'Performance', icon: 'fa-chart-line' },{ value: 'handbook', text: 'Handbook' },{ value: 'scorecard', text: 'Scorecard' },{ value:'planning', text: "Business Planning"}],
}),
watch: {
    
},
methods:{
    setFilter(value) {
        this.selected_filter = value
    },
    select_module(option) {
        this.loading = true
        let value = '/' + option.value
        this.$router.push(value).catch(()=>{});
        //this.$router.push({name: value});
        this.$emit('viewChange', value)
        this.$nextTick(() => {
            this.loading = false
        })
       
    },
    getIcon(value) {
        // <font-awesome-icon icon="fa-bar-chart" />
        // <font-awesome-icon icon="fa-handshake" />
        switch (value) {
            case "model":
              return 'fa-bar-chart'
            case "people":
              return 'fa-users'
            case "performance":
              return 'fa-chart-line'
            case "handbook":
              return 'fa-handshake'
            case "testzone":
              return 'fas fa-star-half-alt'
            default:
               return 'fa-handshake'
          }  
    }
    
},
computed:{
    getModuleName() {
        let value = ''
        this.modules.forEach(module => {
            if(module.value == this.selected_module) {
                value = module.text
            }
        })
        return value
    }

},

}


</script>

<style>
.moduleSelect {
    text-align: center; 
    background-color: #3A0D4A !important;
    color: white !important;
    border-radius: 1.5rem; 
    font-size: 0.7rem !important;
    border: 1px solid #50A5F1;
    height: 2.5rem;
    width: 100%
}
.compactModuleSelect {
    text-align: center; 
    background-color: #3A0D4A !important;
    color: white !important;
    font-size: 0.7rem !important;
    height: 2.5rem;
    border: 1px solid #3A0D4A;
    width: 100%
}

.customDropdown {
    background-color: #3A0D4A !important;
    color: white;
    padding: 0px;
    margin-top: 0px;
    border-radius: 1.45rem; 
    border: 1px solid #50A5F1
}
.compactCustomDropdown {
    background-color: #3A0D4A !important;
    color: white;
    padding: 0px;
    margin-top: 0px;
    border-radius: 1.45rem; 
    border: 1px solid #50A5F1
}
.compactCustomDropdown:hover {
    border: 1px solid #3A0D4A
}
.compactCustomDropdown:focus {
    border: 1px solid #3A0D4A
}
#compactDropdown .dropdown-toggle {
    border: 1px solid #3A0D4A !important;
}
#compactDropdown .btn-secondary:focus {
    box-shadow: none;
}
.customMenuClass {
    color: white !important;
    margin-left: 13px;
    /* padding-top: 20px;
    margin-top: -20px; */
    /* overflow: hidden;
    z-index: 1; */
    padding: 0px;
    margin-top: 0px !important;
    width: 94%;
    background-color: rgb(136, 129, 179);
    border: 1px solid #50A5F1;
}
/* .customItem:hover {
    background-color: red !important;
} */

.customMenuClass .dropdown-item:hover {
    background-color: #3A0D4A !important;
    color:white !important
}
.customItem .dropdown-item {
    color:white !important
}

.arrow-dropdown {
  border: 10px solid #50A5F1;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.icon-styles {
    width: 30px;
    height: 29px;
    border: 1px solid rgb(80, 165, 241);
    border-radius: 50%;
    margin-left: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>
